import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Nav } from "shards-react";
import UserActions from "./UserActions";
import { userActions } from "../../../../_actions";

const NavbarNav = (props) => {
  // const router = useRouter();
  const { categories, getCategories } = props;

  useEffect(() => {
    if (categories.fetched === false && categories.loading === false) {
      getCategories();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Nav navbar className="flex-row mx-auto">
      <UserActions />
    </Nav>
  );
};

const mapState = (state) => ({
  profile: state.profile.items,
  categories: state.template.category,
});

const actionCreators = {
  getCategories: userActions.getTemplateCategories,
};

export default connect(mapState, actionCreators)(NavbarNav);
