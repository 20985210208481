import React from "react";
import { ButtonToolbar } from "shards-react";

const Tabs = props => {
  const { active, changeTab } = props;

  return (
    <ButtonToolbar>
      <div className="listingbtn mx-auto">
        <span className={`${active === 'Popular' ? ' actives' : ''}`} onClick={() => changeTab('Popular')}>Popular</span>
        <span className={`${active === 'Social' ? ' actives' : ''}`} onClick={() => changeTab('Social')}>Social</span>
        <span className={`${active === 'Print' ? ' actives' : ''}`} onClick={() => changeTab('Print')}>Print</span>
        <span className={`${active === 'Custom' ? ' actives' : ''}`} onClick={() => changeTab('Custom')}>Custom</span>
      </div>
    </ButtonToolbar>
  );
}

export default Tabs;