/* eslint-disable import/no-anonymous-default-export */
export default function () {
  return [
    {
      category: "Popular",
      type: "portrait",
      title: "Portrait",
      display_width: 1200,
      display_height: 628,
      display_unit: "px",
      display_orientation : "Horizontal",
      width: 1200,
      height: 628,
      unit: "px",
      compatible_formats : ["facebook","twitter","linkedin","youtube-play"]
    },
    {
      category: "Popular",
      type: "post",
      title: "Post",
      display_width: 1080,
      display_height: 1080,
      display_unit: "px",
      display_orientation : "Square",
      width: 1080,
      height: 1080,
      unit: "px",
      compatible_formats : ["facebook","linkedin","instagram"]
    },
    {
      category: "Popular",
      type: "story",
      title: "Story",
      display_width: 1080,
      display_height: 1920,
      display_unit: "px",
      display_orientation : "Vertical",
      width: 1080,
      height: 1920,
      unit: "px",
      compatible_formats : ["facebook","linkedin","instagram","youtube-play"]
    },
    {
      category: "Popular",
      type: "a4",
      title: "A4",
      display_width: 210,
      display_height: 297,
      display_unit: "mm",
      display_orientation : "Vertical",
      width: 595,
      height: 842,
      unit: "px",
      compatible_formats : ["facebook","youtube-play"]
    },
    {
      category: "Popular",
      type: "letter",
      title: "Letter",
      display_width: 8.5,
      display_height: 11,
      display_unit: "in",
      display_orientation : "Vertical",
      width: 612,
      height: 792,
      unit: "px",
      compatible_formats : ["facebook","youtube-play"]
    },
    {
      category: "Social",
      type: "portrait",
      title: "Portrait",
      display_width: 1200,
      display_height: 628,
      display_unit: "px",
      display_orientation : "Horizontal",
      width: 1200,
      height: 628,
      unit: "px",
      compatible_formats : ["facebook","twitter","linkedin","youtube-play"]
    },
    {
      category: "Social",
      type: "linkedIn",
      title: "LinkedIn",
      display_width: 1280,
      display_height: 720,
      display_unit: "px",
      display_orientation : "Horizontal",
      width: 1280,
      height: 720,
      unit: "px",
      compatible_formats : ["linkedin"]
    },
    {
      category: "Social",
      type: "twitter",
      title: "Twitter",
      display_width: 1024,
      display_height: 512,
      display_unit: "px",
      display_orientation : "Horizontal",
      width: 1024,
      height: 512,
      unit: "px",
      compatible_formats : ["twitter"]
    },
    {
      category: "Social",
      type: "post",
      title: "Post",
      display_width: 1080,
      display_height: 1080,
      display_unit: "px",
      display_orientation : "Square",
      width: 1080,
      height: 1080,
      unit: "px",
      compatible_formats : ["facebook","twitter","linkedin","instagram"]
    },
    {
      category: "Social",
      type: "story",
      title: "Story",
      display_width: 1080,
      display_height: 1920,
      display_unit: "px",
      display_orientation : "Vertical",
      width: 1080,
      height: 1920,
      unit: "px",
      compatible_formats : ["facebook","twitter","instagram"]
    },
    {
      category: "Print",
      type: "a4",
      title: "A4",
      display_width: 210,
      display_height: 297,
      display_unit: "mm",
      display_orientation : "Vertical",
      width: 595,
      height: 842,
      unit: "px",
    },
    {
      category: "Print",
      type: "a5",
      title: "A5",
      display_width: 148,
      display_height: 210,
      display_unit: "mm",
      display_orientation : "Vertical",
      width: 420,
      height: 595,
      unit: "px",
    },
    {
      category: "Print",
      type: "letter",
      title: "Letter",
      display_width: 8.5,
      display_height: 11,
      display_unit: "in",
      display_orientation : "Vertical",
      width: 612,
      height: 792,
      unit: "px",
    },
    {
      category: "Print",
      type: "poster",
      title: "Poster",
      display_width: 11,
      display_height: 17,
      display_unit: "in",
      display_orientation : "Vertical",
      width: 792,
      height: 1224,
      unit: "px",
    },
    {
      category: "Print",
      type: "card",
      title: "Post Card",
      display_width: 5,
      display_height: 7,
      display_unit: "in",
      display_orientation : "Vertical",
      width: 360,
      height: 504,
      unit: "px",
    },
    {
      category: "Print",
      type: "card_1",
      title: "Post Card",
      display_width: 3.5,
      display_height: 5,
      display_unit: "in",
      display_orientation : "Vertical",
      width: 252,
      height: 360,
      unit: "px",
    }
  ];
}
