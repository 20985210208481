import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarLogo from "./NavbarLogo";
import NavbarEnterprise from "./NavbarEnterprise";

const MainNavbar = ({ layout, stickyTop, profile }) => {
  const [clientWindowHeight, setClientWindowHeight] = useState("");

  const handleScroll = () => {
    setClientWindowHeight(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top",
    clientWindowHeight > 150 && "shadow-navbar"
  );

  return (
    <div className={classes}>
      <Container fluid className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <NavbarLogo />
          <div>
            {window.location.pathname === "/templates" ? (
              <NavbarEnterprise />
            ) : (
              ""
            )}
            <NavbarNav profile={profile} />
          </div>
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

const mapState = (state) => ({
  profile: state.profile.items,
});

export default connect(mapState)(MainNavbar);
