import { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { ProductFruits } from 'react-product-fruits';
import _ from "lodash";

function ProductFruitContainer(props) {
  const { profile } = props;
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    if (!_.isEmpty(profile) && _.isEmpty(userInfo)) {
      setUserInfo({
        username: profile.id,
        firstname: profile.first_name,
        signUpAt: profile.created_at,
        role: profile.user_type,
      })
    }
    // eslint-disable-next-line
  }, [profile])

  return (
    <>
      {!_.isEmpty(userInfo) &&
        <ProductFruits workspaceCode={process.env.REACT_APP_PRODUCTFRUITS_KEY} language="en" user={userInfo} />
      }
    </>
  )
}

const mapState = state => ({
  profile: state.profile.items
});

export default connect(mapState)(ProductFruitContainer);