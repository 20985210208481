import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
} from "shards-react";

import { userActions } from "../../../../_actions";

const UserActions = (props) => {
  // const router = useRouter()
  const { profile } = props;

  const [visible, setVisible] = useState(false);

  const user_types = profile.user_type.split(",");

  const toggleUserActions = () => {
    setVisible(!visible);
  };

  return (
    <NavItem
      tag={Dropdown}
      caret
      toggle={toggleUserActions}
      style={{ float: "right" }}
    >
      <DropdownToggle tag="a" className="text-nowrap pr-3 pl-1 nav-link">
        <div className="flex-align-center">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("assets/img/avatars/user.svg").default}
            alt="User Avatar"
            style={{ filter: "grayscale(100%)", width: "40px", height: "40px" }}
          />

          <span style={{ display: "inline-grid" }}>
            <span
              className="d-none d-md-inline-block"
              style={{ fontSize: "14px", color: "black", fontWeight: "500" }}
            >
              {profile.first_name} {profile.last_name}
            </span>
            <span className="d-none d-md-inline-block">
              {user_types.includes("admin") ? "Admin" : "Creator"}
            </span>
          </span>
        </div>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem style={{ color: "rgb(255, 1, 125)" }}>
          <Link to={"/logout"}>
            <i className="material-icons">&#xE879;</i> Logout
          </Link>
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

const mapState = (state) => ({
  profile: state.profile.items,
});

const actionCreators = {
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(UserActions);
