import { enterpriseConstants } from "../../_constants";

const initialState = {
  detail: {},
  loading: false,
  error: null,
};

export default function template(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.TEMPLATE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case enterpriseConstants.TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.detail,
      };
    case enterpriseConstants.TEMPLATE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detail: {},
      };
    default:
      return state;
  }
}
