export function enterpriseHeader() {
  // return Enterprise Company ID to get Templates
  let Enterprise = JSON.parse(localStorage.getItem("enterprise"));
  const id = btoa(Enterprise.id.toString());

  if (Enterprise && Enterprise.id) {
    return { company: id };
  } else {
    return {};
  }
}
