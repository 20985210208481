import React from "react";
import { Link } from "react-router-dom";

const NavbarLogo = () => {
  return (
    <Link to="/" className="ml-4 p-0 mt-1">
      <div className="d-table">
        <img
          src={require("../../../assets/img/brand/logo.png").default}
          alt="logo"
          style={{ marginTop: "3px", height: "42px", width: "156px" }}
        />
      </div>
    </Link>
  );
};

export default NavbarLogo;
