import { enterpriseConstants } from "../_constants";

const initialState = {
  detail: {},
  loading: false,
  error: null,
};

export function enterpriseList(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.GET_ENTERPRISE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case enterpriseConstants.GET_ENTERPRISE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.items,
      };
    case enterpriseConstants.GET_ENTERPRISE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detail: {},
      };
    default:
      return state;
  }
}
