import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
//import ScrollToTop from 'react-router-scroll-top'

import { routes, privateroutes } from "../routes";

import { history } from "../_helpers";
import { alertActions, userActions } from "../_actions";
import { PrivateRoute } from "../_components";
import { ToastContainer } from "react-toastify";
import NotFound from "components/common/NotFound";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/css/custom.css";
import UploadPrivate from "views/uploadMedia/UploadPrivate";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  componentDidMount() {
    const { categories } = this.props;
    if (categories.fetched === false && categories.loading === false) {
      this.props.getCategories();
    }
  }

  render() {
    return (
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <ToastContainer autoClose={3000} />
        <Switch>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return route.layout ? (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  ) : (
                    <route.component {...props} />
                  );
                }}
              />
            );
          })}
          {privateroutes.map((route, index) => {
            return (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={(props) => {
                  return route.layout ? (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  ) : (
                    <route.component {...props} />
                  );
                }}
              />
            );
          })}
          <Route path="/upload-media/private" component={UploadPrivate} />
          <Route component={NotFound} />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  }
}

function mapState(state) {
  return {
    categories: state.template.category,
  };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
  getCategories: userActions.getTemplateCategories,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
