import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { forgotpass } from "./forgotpass.reducer";
import { profile } from "./profile.reducer";
import { config } from "./config.reducer";
import { alert } from "./alert.reducer";
import { template } from "./template.reducer";
import { enterpriseList } from "./enterpriseList.reducer";
import { brandingSelect } from "./brandingSelect.reducer";
import { current_category } from "./current_category.reducer";
import { uploadMediaUser } from "./user.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  forgotpass,
  profile,
  config,
  alert,
  template,
  enterpriseList,
  brandingSelect,
  current_category,
  uploadMediaUser,
});

export default rootReducer;
