import { commonConstants } from '../_constants';
import { userService,service } from '../_services';
import { uniqueFilename } from '../utils/unique';
import { toast } from "react-toastify";
import _ from 'lodash';
import axios from 'axios';

export const commonActions = {
    getConfig,
    getPricing,
    getFaq,
    /* getCardCategories,
    getCardCategoriesImages, */
    getCategoryImages,
    getImageKeywords,
    manageSubscriptionStatus,
    manageBilling,
    applyCouponCode,
    removeCouponCode,
    toggleSidebar,
    resizeSidebar,
    subscribeIntrestedUser,
    EarlyStageApplicationSave,
    EarlyStageApplicationReset,
    uploadMediaEditor,
    verifyRedirect,
};

function getConfig() {
    return dispatch => {
        dispatch(request());

        const url = 'config';
        const method = 'GET';

        service.send(url,method)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.GET_CONFIG_REQUEST } }
    function success(detail) { return { type: commonConstants.GET_CONFIG_SUCCESS, detail } }
    function failure(error) { return { type: commonConstants.GET_CONFIG_FAILURE, error } }
}

function getPricing() {
    return dispatch => {
        dispatch(request());

        userService.getPricing()
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.GETPRICING_REQUEST } }
    function success(items) { return { type: commonConstants.GETPRICING_SUCCESS, items } }
    function failure(error) { return { type: commonConstants.GETPRICING_FAILURE, error } }
}

function getFaq() {
    return dispatch => {
        dispatch(request());

        userService.getFaq()
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.FAQ_REQUEST } }
    function success(items) { return { type: commonConstants.FAQ_SUCCESS, items } }
    function failure(error) { return { type: commonConstants.FAQ_FAILURE, error } }
}

/* function getCardCategories() {
    return dispatch => {
        dispatch(request());

        userService.getCardCategories()
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.CARD_CATEGORY_REQUEST } }
    function success(items) { return { type: commonConstants.CARD_CATEGORY_SUCCESS, items } }
    function failure(error) { return { type: commonConstants.CARD_CATEGORY_FAILURE, error } }
}

function getCardCategoriesImages(id) {
    return dispatch => {
        dispatch(request());
        userService.getCardCategoriesImages(id)
            .then(
                response => dispatch(success(response.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.CARD_CATEGORY_IMAGES_REQUEST } }
    function success(items) { return { type: commonConstants.CARD_CATEGORY_IMAGES_SUCCESS, items } }
    function failure(error) { return { type: commonConstants.CARD_CATEGORY_IMAGES_FAILURE, error } }
} */

function getCategoryImages(page, query) {
    return dispatch => {

        let filterStr = {
            query,
            page,
        }
        var compacted = _.pickBy(filterStr);
        let dataStr = new URLSearchParams(compacted).toString()
        let isLoadMore = false;
        
        if (page) {
            page = parseInt(page);
            if(!isNaN(page) && page > 1){
              isLoadMore = true;
            }
        }
        if(isLoadMore === false){
            dispatch(request());
        }
          
        const url = `category-images?${dataStr}`;
        const method = 'GET';
        service.send(url,method)
            .then(
                response => {
                    const paging = response.data;
                    const items = response.data.data;
                    delete paging.data;
                    if(isLoadMore)
                    dispatch(successLoadMore(items,paging))
                    else
                    dispatch(success(items,paging))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: commonConstants.CARD_CATEGORY_IMAGES_REQUEST } }
    function success(items,paging) { return { type: commonConstants.CARD_CATEGORY_IMAGES_SUCCESS, items,paging } }
    function successLoadMore(items,paging) { return { type: commonConstants.CARD_CATEGORY_IMAGES_LOAD_MORE_SUCCESS, items,paging } }
    function failure(error) { return { type: commonConstants.CARD_CATEGORY_IMAGES_FAILURE, error } }
}

function manageSubscriptionStatus(type,message = null) {
    return dispatch => {
        if(type === 'request'){
            dispatch(request());
        } else if(type === 'success'){
            dispatch(success(message));
        } else if(type === 'error'){
            dispatch(failure(message));
        }
    };

    function request() { return { type: commonConstants.SUBSCRIPTION_REQUEST } }
    function success(message) { return { type: commonConstants.SUBSCRIPTION_SUCCESS, message } }
    function failure(error) { return { type: commonConstants.SUBSCRIPTION_FAILURE, error } }
}

function manageBilling() {
    return dispatch => {
        var return_url = window.location.origin.concat("/account");

        const url = 'account/manage-billing';
        const method = 'POST';
        const data = {return_url};

        service.sendAuth(url,method,data)
        .then(
            response => {
                window.location.href = response.data.url;
            },
            error => {
                
            }
        );
    };
}

function applyCouponCode(data) {
    return dispatch => {
        dispatch(request());
        
        const url = 'apply-coupon';
        const method = 'POST';

        service.sendAuth(url,method,data)
            .then(
                response => {
                    dispatch(success(response.data));
                    toast.success(response.message);
                    //dispatch(alertActions.success('Coupon code applied successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString());
                    //dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: commonConstants.COUPON_CODE_APPLY_REQUEST } }
    function success(items) { return { type: commonConstants.COUPON_CODE_APPLY_SUCCESS, items } }
    function failure(error) { return { type: commonConstants.COUPON_CODE_APPLY_FAILURE, error } }
}

function removeCouponCode() {
    return dispatch => {
        dispatch(success([]));
    };

    function success(items) { return { type: commonConstants.COUPON_CODE_APPLY_SUCCESS, items } }
}

function toggleSidebar(status) {
    return dispatch => {
        dispatch(success(status));
    };

    function success(status) { return { type: commonConstants.TOGGLE_SIDEBAR, status } }
}

function resizeSidebar(status) {
    return dispatch => {
        localStorage.setItem('sidebar', status);
        dispatch(success(status));
    };

    function success(status) { return { type: commonConstants.RESIZE_SIDEBAR, status } }
}

function subscribeIntrestedUser(data) {
    return dispatch => {
        dispatch(request());
        
        const url = 'interested-users';
        const method = 'POST';

        service.sendAuth(url,method,data)
            .then(
                response => {
                    localStorage.setItem('candidate_engagement', 'allow');
                    dispatch(success());
                },
                error => {
                    localStorage.removeItem('candidate_engagement');
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: commonConstants.CANDIDATE_ENGAGEMENT_REQUEST } }
    function success() { return { type: commonConstants.CANDIDATE_ENGAGEMENT_SUCCESS } }
    function failure(error) { return { type: commonConstants.CANDIDATE_ENGAGEMENT_FAILURE, error } }
}

function EarlyStageApplicationSave(data) {
    return dispatch => {
        dispatch(request());
        
        const url = 'early-stage-applications';
        const method = 'POST';

        service.sendAuth(url,method,data)
            .then(
                response => {
                    dispatch(success());
                    toast.success(response.message);
                },
                error => {
                    dispatch(failure(error.toString()));
                    toast.error(error.toString());
                }
            );
    };

    function request() { return { type: commonConstants.EARLY_STAGE_APPLY_REQUEST } }
    function success() { return { type: commonConstants.EARLY_STAGE_APPLY_SUCCESS } }
    function failure(error) { return { type: commonConstants.EARLY_STAGE_APPLY_FAILURE, error } }
}

function EarlyStageApplicationReset() {
    return dispatch => {
        dispatch(reset());
    };

    function reset() { return { type: commonConstants.EARLY_STAGE_APPLY_RESET } }
}

function getImageKeywords(data) {
    return dispatch => {
        dispatch(request());
        
        const url = 'image-recommendations';
        const method = 'POST';

        service.send(url,method,data)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    toast.error(error.toString());
                }
            );
    };

    function request() { return { type: commonConstants.IMAGE_KEYWORDS_SUGGEST_REQUEST } }
    function success(items) { return { type: commonConstants.IMAGE_KEYWORDS_SUGGEST_SUCCESS, items } }
}

function uploadMediaEditor(file, id, params) {
    return async (dispatch) => {
        dispatch(request());
        const url = "uploads";
        const method = "POST";
        let formData = [];

        for (let i = 0; i < file.length; i++) {
            const selectedFile = file[i]
            const folderName = 'users/' + id + '/uploads/';
            const updatedFileName = uniqueFilename(selectedFile.name)
            const awsFileName = folderName + updatedFileName
            const updatedFile = new File([selectedFile], updatedFileName)

            const data = { name: awsFileName, type: selectedFile.type }
            const presignedurl = "presignedurl"
            const response = await service.sendAuth(presignedurl, method, data).then(
                data => data
            ).catch((err) => console.log("err", err))

            await axios.put(response.url, updatedFile, {
                headers: { "Content-Type": file.type },
            })
            const uploadFile = { image_name: updatedFileName, img_type: selectedFile.type, org_name: selectedFile.name, size: selectedFile.size, folder: folderName }
            formData.push(uploadFile);
        }
        await service.sendAuth(url, method, formData).then(
            (response) => {
                toast.success(response.message);
                params.callback(); // <------ Callback here
            },
            (error) => {
                toast.error(error.toString());
            }
        );


    };

    function request() {
        return { type: commonConstants.UPLOAD_MEDIA_EDITOR_REQUEST };
    }
    /* function success(items) {
        return { type: commonConstants.UPLOAD_MEDIA_EDITOR_SUCCESS, items };
    }
    function failure(error) {
        return { type: commonConstants.UPLOAD_MEDIA_EDITOR_FAILURE, error };
    } */
}

function verifyRedirect(data, params) {
    return (dispatch) => {
        if (!_.isEmpty(data)) {
            localStorage.setItem('user', JSON.stringify(data))
            dispatch(success());
            params.callback();
        }
    };

    function success() {
        return { type: commonConstants.VERIFY_REDIRECT_STATUS, data };
    }
}
