import React from "react";
import PropTypes from "prop-types";

import OuterNavbar from "../components/layout/Navbars/OuterNavbar";
import CardsFooter from "../components/layout/Footers/SimpleFooter";

const DefaultLayout = ({ children, noNavbar, noFooter, noLoginBtn }) => (
  <>
    {!noNavbar && <OuterNavbar noLoginBtn={noLoginBtn} />}
    {children}
    {!noFooter && <CardsFooter />}
  </>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default DefaultLayout;
