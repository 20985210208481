
import React from "react";
import { connect } from 'react-redux';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import md5 from 'crypto-js/md5';
import _ from 'lodash';

// reactstrap components
import { Container, Row, Col, Card,
  FormGroup,
  Form,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal
 } from "reactstrap";
import {ButtonLoading,BlockLoading} from "../../components/Loading";
import { userActions } from '../../_actions';
const MySwal = withReactContent(Swal);

class Templates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      catActive: "all",
      slug: props.slug,
      categoryModal: props.category_change.open || false,
      selected: {},
      form:{
        category : ""
      },
      submitted : false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {templates,slug} = this.props;
    if(templates.loading === false){
      this.props.getTemplates(slug);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.category_change.open !== nextProps.category_change.open) {
      this.setState({"categoryModal" : nextProps.category_change.open});
    }
  }

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  }

  handleChangeCategory = (data) => {
    this.setState({
      selected: data
    }, () => {
      this.toggleModal("categoryModal");
    });
  }

  selectCategory = id => {
    this.setState({ catActive: id });
  }

  handleEdit = id => {
    let newId = md5(id.toString());
    window.location.href = process.env.REACT_APP_BASE_URL+'/editor/edit/'+newId;
  }

  handleDelete = detail => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.props.deleteTemplate(detail);        
      }
    })
  }

  handleGlobalConfirm = detail => {
    const {set_global} = detail;
    let title = '';
    let value = set_global;
    if(set_global === 'No'){
      title = "Do you want to mark this template as Global"
      value = "Yes";
    } else {
      title = "Do you want to remove this template as Global"
      value = "No";
    }

    MySwal.fire({
      title: 'Are you sure?',
      text: title,
      icon: '',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.value) {
        let id = md5(detail.id.toString());
        this.props.changeGlobal(id,{set_global: value},{category:detail.cat_id,id : detail.id});
      }
    })
  }

  handleClone = detail => {
    let id = md5(detail.id.toString())
    this.props.cloneTemplate(id);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      form:{ [name]: value }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { form,selected } = this.state;
    if (form.category) {
      let id = selected.id;
      this.props.changeCategory(id,form);
    }
  }

  render() {
    const { categories,templates, category_change, clone } = this.props;
    const { slug } = this.state;
    let category_name = '';
    if(!_.isEmpty(categories.items)){
      let filter_category  = categories.items.filter(item => item.slug === slug);
      category_name = filter_category[0].title;
    }

    return (
      <>
        <div className="position-relative">
          <section className="section section-lg">
          {clone.loading && <BlockLoading title=" Cloning... Please Wait!" />}
            <Container>
              <Row>
                <Col sm="12" className="mt-5 mb-4 d-flex align-items-center justify-content-between">
                  <h3>{category_name}</h3>
                </Col>
                {templates.items.map((tmp, i) => (
                  <Col key={`t_${i}`} sm="6" md="6" lg="4" xl="3">
                    <Card className={`mb-5 template-list ${tmp.set_global === 'Yes' ? 'border border-success': ''}`}>
                      {tmp.preview &&
                      <img className="img-fluid" src={tmp.preview} alt="" />
                      }
                      {/* <div className="bg-light" style={{height:"130px"}} /> */}
                      
                      <UncontrolledDropdown className="btnsection">
                        <DropdownToggle tag="button" className="btn btn-link text-dark p-0">
                          <i className="fa fa-ellipsis-h" />
                        </DropdownToggle>
                        <DropdownMenu
                          aria-labelledby="navbar-default_dropdown_1"
                          right
                        >
                          <DropdownItem
                            onClick={e => this.handleClone(tmp)}
                          >
                            <i className="fa fa-copy"></i> Make a Copy
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => this.handleChangeCategory({id:tmp.id,category:tmp.cat_id})}
                          >
                            <i className="fa fa-folder-o"></i> Change Category
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => this.handleEdit(tmp.id)}
                          >
                            <i className="fa fa-pencil-square-o"></i> Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => this.handleDelete({id:tmp.id,category:tmp.cat_id})}
                          >
                            <i className="fa fa-window-close-o"></i> Delete
                          </DropdownItem>
                          <DropdownItem
                            onClick={e => this.handleGlobalConfirm(tmp)}
                          >
                            <i className="ni ni-world-2" /> Mark Global
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Card>
                  </Col>               
                ))}
                {(_.isEmpty(templates.items)) && 
                <Col>No Template Records</Col>
                }
              </Row>
              {/* } */}
            </Container>
            <>
              {/* Modal */}
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.categoryModal}
                toggle={() => this.toggleModal("categoryModal")}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="categoryModalLabel">
                    Change Category
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("categoryModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <Form role="form" onSubmit={this.handleSubmit}>
                  <div className="modal-body">
                    <Row>
                      <Col md="10" className="mx-auto">
                        <FormGroup>
                          <select className="form-control" name="category" defaultValue={this.state.selected.category} onChange={this.handleChange}>
                            <option value="">Select</option>
                            {categories.items.map((tc, i) => (
                              <option key={`change_c_${i}`} value={tc.id}>{tc.title}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="modal-footer">
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("categoryModal")}
                    >
                      Close
                    </Button>
                    <Button color="primary" type="submit" disabled={category_change.loading}>
                      {category_change.loading ? <ButtonLoading showText={true}/> : `Save changes` }
                    </Button>
                  </div>
                </Form>
              </Modal>
            </>
          </section>
        </div>
      </>
    );
  }
}

function mapState(state) {
  return ({
    categories: state.template.category,
    templates : state.template.templatesbycat,
    category_change : state.template.category_change,
    clone : state.template.clone,
  });
}

const actionCreators = {
  getTemplates: userActions.getTemplatesByCategory,
  deleteTemplate: userActions.deleteTemplate,
  changeGlobal: userActions.changeTemplateGlobal,
  changeCategory: userActions.changeTemplateCategory,
  cloneTemplate: userActions.cloneTemplate
};

export default connect(mapState, actionCreators)(Templates);