/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import TemplateList from "./IndexSections/TemplateList";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <main className="main-content main-content-inner" ref="main">
        <TemplateList />
      </main>
    );
  }
}

export default Index;
