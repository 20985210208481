import { enterpriseConstants } from "../_constants";
let detail = JSON.parse(localStorage.getItem("category"));

const initialState = {
  detail
};

export function current_category(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.CATEGORY_SELECT_SUCCESS:
      return {
        ...state,
        detail: action.detail,
      };
    default:
      return state;
  }
}
