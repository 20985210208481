import React from "react";
import PropTypes from "prop-types";

// import DemoNavbar from "../components/layout/Navbars/DemoNavbar";
import MainNavbar from "../components/layout/Navbars/MainNavbar";
import CardsFooter from "../components/layout/Footers/SimpleFooter";

const InsideLayout = ({ children, noNavbar, noFooter }) => (
  <>
    {!noNavbar && <MainNavbar />}
    {children}
    {!noFooter && <CardsFooter />}
  </>
);

InsideLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

InsideLayout.defaultProps = {
  noNavbar: false,
  noFooter: true,
};

export default InsideLayout;
