import React, { useEffect, useState } from 'react'
import _ from "lodash";
import { connect } from 'react-redux';
import { commonActions } from '../../_actions';
import { useHistory, useLocation } from 'react-router';
import { Loading } from 'components/Loading';
import { DefaultLayout } from 'layouts';

function UploadPrivate(props) {
    const router = useHistory()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const { user, verifyRedirect } = props
    const [userData, setUserData] = useState()

    useEffect(() => {
        if (!token) {
            router.push("/not-exist")
        } else {
            loginUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    async function loginUser() {
        let user = await fetch(process.env.REACT_APP_API_URL + '/upload-media/verify-token',
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                },
            }
        ).then(
            response => {
                if (response.status === 200) {
                    return response.json()
                }
            },
            error => {
                console.log("error1", error);
                return []
            }
        ).then(
            data => data,
            error => {
                console.log("error2", error);
                return []
            }
        )

        if (user !== undefined) {
            setUserData(user.data)
        } else {
            router.push('/not-exist')
        }
    }

    useEffect(() => {
        if (!_.isEmpty(userData)) {
            verifyRedirect(userData, {
                callback: function () {
                    router.push(
                        {
                            pathname: "/upload-media/files",
                        },
                    );
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    // verifyRedirect(user, {
    //     callback: function () {
    //         router.push(
    //             {
    //                 pathname: "/upload-media/files",
    //             },
    //         );
    //     }
    // })

    return (
        <>
            <DefaultLayout noLoginBtn={true}>
                <Loading />
            </DefaultLayout>
        </>
    )
}

// export async function getServerSideProps(context) {
//     const token = context.query.token;

//     if (!token) {
//         return {
//             notFound: true
//         }
//     }

//     let user = await fetch(process.env.NEXT_PUBLIC_API_URL + '/e/upload-media/verify-token',
//         {
//             method: "GET",
//             headers: {
//                 "Content-Type": "application/json",
//                 "Authorization": "Bearer " + token
//             },
//         }
//     ).then(
//         response => {
//             if (response.status === 200) {
//                 return response.json()
//             }
//         },
//         error => {
//             console.log(error);
//             return []
//         }
//     ).then(
//         data => data,
//         error => {
//             console.log(error);
//             return []
//         }
//     )

//     if (_.isEmpty(user)) {
//         return {
//             // notFound: true
//             redirect: {
//                 permanent: false,
//                 destination: "/upload-media/not-exist",
//             }
//         }
//     }


//     return {
//         props: {
//             user: user.data,
//         },
//     };
// }

const actionCreators = {
    verifyRedirect: commonActions.verifyRedirect
};

export default connect(null, actionCreators)(UploadPrivate);
