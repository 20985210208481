import { userConstants } from '../../_constants';

const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null
};

export default function category(state = initialState, action) {
  switch (action.type) {
    case userConstants.TEMPLATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.TEMPLATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        items: action.items
      };
    case userConstants.TEMPLATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: []
      };
    default:
      return state
  }
}
