import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  // Card,
  // CardBody,
  // CardHeader,
  Row,
  Col,
} from "shards-react";

import { Button, Card, CardBody, Form, Container } from "reactstrap";
import { enterpriseActions } from "../_actions";
import _ from "lodash";

function EnterpriseList(props) {
  const { enterprise, brandSelect } = props;

  const { detail } = enterprise;
  const history = useHistory();

  const [submitted, setSubmitted] = useState(false);
  const [selectedEnterprise, setSelectedEnterprise] = useState(
    brandSelect.selected
  );

  const handleCheck = (e) => {
    if (!_.isEmpty(selectedEnterprise)) {
      setSubmitted(false);

      props.brandingSelectAction(selectedEnterprise);
      history.push({ pathname: `/templates` });
    } else {
      setSubmitted(true);
    }
  };
  useEffect(() => {
    props.getEnterpriseList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const item = detail.filter((item) => item.slug === e.target.value);
    setSelectedEnterprise(item[0]);
    // setcompanyEmpty(false);
  };

  return (
    <main className="main-content main-content-inner">
      <section className="section section-shaped section-lg pt-5 pb-5">
        <Container className="pt-lg-5 pt-5 mt-5">
          <Row className="justify-content-center">
            <Col xl="6" lg="6" sm="10" md="10" xs="10">
              <Card className="shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div
                    className="text-center text-muted mb-4"
                    style={{ fontSize: "20px" }}
                  >
                    <div>Choose Enterprise</div>
                  </div>
                  <Form role="form">
                    <select
                      className={`custom-select mb-3 ${
                        submitted && _.isEmpty(selectedEnterprise)
                          ? "is-invalid"
                          : ""
                      }`}
                      value={
                        !_.isEmpty(selectedEnterprise)
                          ? selectedEnterprise.slug
                          : ""
                      }
                      onChange={(e) => handleChange(e)}
                    >
                      <option defaultValue>Select Enterprise</option>
                      {!_.isEmpty(detail) &&
                        detail.map((item, index) => {
                          return (
                            <option key={index} value={item.slug}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                    {submitted && _.isEmpty(selectedEnterprise) ? (
                      <div className="text-danger text-center">
                        <i class="fa fa-exclamation-circle fa-fw"></i>
                        Please select an Enterprise
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-center">
                      <Button
                        className="mt-3"
                        color="primary"
                        type="button"
                        size="lg"
                        onClick={() => handleCheck()}
                      >
                        Select
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
}

function mapState(state) {
  return {
    enterprise: state.enterpriseList,
    brandSelect: state.brandingSelect,
  };
}

const actionCreators = {
  brandingSelectAction: enterpriseActions.brandingSelect,
  getEnterpriseList: enterpriseActions.getEnterpriseList,
};

export default connect(mapState, actionCreators)(EnterpriseList);
