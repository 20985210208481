import { enterpriseConstants } from "../../_constants";

const initialState = {
  items: [],
  loading: false,
  loading_submit: false,
  created: false,
  fetched: false,
  error: null,
};

export default function curatedTemplates(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.GET_CURATED_TEMPLATES_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      };
    case enterpriseConstants.GET_CURATED_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        items: action.items,
      };
    case enterpriseConstants.GET_CURATED_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: [],
      };
    case enterpriseConstants.TEMPLATE_CREATE_REQUEST:
      return {
        ...state,
        created: true,
      };
    case enterpriseConstants.TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        created: false,
        items: [action.detail, ...state.items],
      };
    case enterpriseConstants.TEMPLATE_CREATE_FAILURE:
      return {
        ...state,
        loading_submit: false,
        error: action.error,
        items: [],
      };
    case enterpriseConstants.TEMPLATE_CLONE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: [action.detail, ...state.items],
      };
    case enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((item) => item.id !== action.detail.id),
      };

    case enterpriseConstants.TEMPLATE_CHANGE_GLOBAL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map((item) =>
          item.id === action.detail.id
            ? { ...item, set_global: action.detail.set_global }
            : item
        ),
      };
    case enterpriseConstants.TEMPLATE_DELETE_REQUEST:
      return {
        ...state,
        loading_submit: true,
        error: null,
      };
    case enterpriseConstants.TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        loading_submit: false,
        items: state.items.filter((item) => item.id !== action.id),
      };
    case enterpriseConstants.TEMPLATE_DELETE_FAILURE:
      return {
        ...state,
        loading_submit: false,
        error: action.error,
      };
    default:
      return state;
  }
}
