import { enterpriseConstants } from "../../_constants";

const initialState = {
  open: false,
  loading: false,
  error: null,
};

export default function category_change(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
        open: true,
        error: null,
      };
    case enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        open: false,
      };
    case enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
