export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  FORGOT_PASSWORD_REQUEST: "USERS_FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "USERS_FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "USERS_FORGOT_PASSWORD_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  PROFILE_REQUEST: "USERS_PROFILE_REQUEST",
  PROFILE_SUCCESS: "USERS_PROFILE_SUCCESS",
  PROFILE_FAILURE: "USERS_PROFILE_FAILURE",

  PROFILE_UPDATE_REQUEST: "USERS_PROFILE_UPDATE_REQUEST",
  PROFILE_UPDATE_SUCCESS: "USERS_PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILURE: "USERS_PROFILE_UPDATE_FAILURE",

  PROFILE_EDIT_CHANGE_MODE: "USERS_PROFILE_EDIT_CHANGE_MODE",

  CHANGE_PASSWORD_REQUEST: "USERS_PROFILE_CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_SUCCESS: "USERS_PROFILE_CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "USERS_PROFILE_CHANGE_PASSWORD_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  BRAND_COLOR_UPDATE_REQUEST: "USERS_BRAND_COLOR_UPDATE_REQUEST",
  BRAND_COLOR_UPDATE_SUCCESS: "USERS_BRAND_COLOR_UPDATE_SUCCESS",
  BRAND_COLOR_UPDATE_FAILURE: "USERS_BRAND_COLOR_UPDATE_FAILURE",

  BRAND_LOGO_UPLOAD_REQUEST: "USERS_BRAND_LOGO_UPLOAD_REQUEST",
  BRAND_LOGO_UPLOAD_SUCCESS: "USERS_BRAND_LOGO_UPLOAD_SUCCESS",
  BRAND_LOGO_UPLOAD_FAILURE: "USERS_BRAND_LOGO_UPLOAD_FAILURE",

  BRAND_LOGO_REQUEST: "USERS_BRAND_LOGO_REQUEST",
  BRAND_LOGO_SUCCESS: "USERS_BRAND_LOGO_SUCCESS",
  BRAND_LOGO_FAILURE: "USERS_BRAND_LOGO_FAILURE",

  BRAND_LOGO_DELETE_REQUEST: "USERS_BRAND_LOGO_DELETE_REQUEST",
  BRAND_LOGO_DELETE_SUCCESS: "USERS_BRAND_LOGO_DELETE_SUCCESS",
  BRAND_LOGO_DELETE_FAILURE: "USERS_BRAND_LOGO_DELETE_FAILURE",

  BRAND_IMAGE_UPLOAD_REQUEST: "USERS_BRAND_IMAGE_UPLOAD_REQUEST",
  BRAND_IMAGE_UPLOAD_SUCCESS: "USERS_BRAND_IMAGE_UPLOAD_SUCCESS",
  BRAND_IMAGE_UPLOAD_FAILURE: "USERS_BRAND_IMAGE_UPLOAD_FAILURE",

  BRAND_IMAGE_REQUEST: "USERS_BRAND_IMAGE_REQUEST",
  BRAND_IMAGE_SUCCESS: "USERS_BRAND_IMAGE_SUCCESS",
  BRAND_IMAGE_FAILURE: "USERS_BRAND_IMAGE_FAILURE",

  BRAND_IMAGE_DELETE_REQUEST: "USERS_BRAND_IMAGE_DELETE_REQUEST",
  BRAND_IMAGE_DELETE_SUCCESS: "USERS_BRAND_IMAGE_DELETE_SUCCESS",
  BRAND_IMAGE_DELETE_FAILURE: "USERS_BRAND_IMAGE_DELETE_FAILURE",

  BRAND_FONT_UPLOAD_REQUEST: "USERS_BRAND_FONT_UPLOAD_REQUEST",
  BRAND_FONT_UPLOAD_SUCCESS: "USERS_BRAND_FONT_UPLOAD_SUCCESS",
  BRAND_FONT_UPLOAD_FAILURE: "USERS_BRAND_FONT_UPLOAD_FAILURE",

  BRAND_FONT_LIST_REQUEST: "USERS_BRAND_FONT_LIST_REQUEST",
  BRAND_FONT_LIST_SUCCESS: "USERS_BRAND_FONT_LIST_SUCCESS",
  BRAND_FONT_LIST_FAILURE: "USERS_BRAND_FONT_LIST_FAILURE",

  BRAND_FONT_DELETE_REQUEST: "USERS_BRAND_FONT_DELETE_REQUEST",
  BRAND_FONT_DELETE_SUCCESS: "USERS_BRAND_FONT_DELETE_SUCCESS",
  BRAND_FONT_DELETE_FAILURE: "USERS_BRAND_FONT_DELETE_FAILURE",

  BRAND_TEMPLATE_REQUEST: "USERS_BRAND_TEMPLATE_REQUEST",
  BRAND_TEMPLATE_SUCCESS: "USERS_BRAND_TEMPLATE_SUCCESS",
  BRAND_TEMPLATE_FAILURE: "USERS_BRAND_TEMPLATE_FAILURE",

  TEMPLATE_CATEGORY_REQUEST: "USERS_TEMPLATE_CATEGORY_REQUEST",
  TEMPLATE_CATEGORY_SUCCESS: "USERS_TEMPLATE_CATEGORY_SUCCESS",
  TEMPLATE_CATEGORY_FAILURE: "USERS_TEMPLATE_CATEGORY_FAILURE",

  TEMPLATE_CAT_LIST_REQUEST: "USERS_TEMPLATE_CAT_LIST_REQUEST",
  TEMPLATE_CAT_LIST_SUCCESS: "USERS_TEMPLATE_CAT_LIST_SUCCESS",
  TEMPLATE_CAT_LIST_FAILURE: "USERS_TEMPLATE_CAT_LIST_FAILURE",

  TEMPLATE_BY_CAT_LIST_REQUEST: "USERS_TEMPLATE_BY_CAT_LIST_REQUEST",
  TEMPLATE_BY_CAT_LIST_SUCCESS: "USERS_TEMPLATE_BY_CAT_LIST_SUCCESS",
  TEMPLATE_BY_CAT_LIST_FAILURE: "USERS_TEMPLATE_BY_CAT_LIST_FAILURE",

  TEMPLATE_LIST_REQUEST: "USERS_TEMPLATE_LIST_REQUEST",
  TEMPLATE_LIST_SUCCESS: "USERS_TEMPLATE_LIST_SUCCESS",
  TEMPLATE_LIST_FAILURE: "USERS_TEMPLATE_LIST_FAILURE",
};
