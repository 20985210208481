//import React from "react";
//import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, InsideLayout } from "./layouts";

// Route Views
import Index from "./views/Index";
import Enterprise from "components/EnterpriseList";
import Login from "./views/examples/Login";
//import Landing from "./views/examples/Landing.js";
import Logout from "./views/examples/Logout";
import Templates from "./views/TemplateCategory";
import UploadMedia from "./views/uploadMedia/UploadMedia";
// import UploadPrivate from "./views/uploadMedia/UploadPrivate";
//import EmailVerification from "./views/examples/EmailVerification";
//import ForgotPassword from "./views/examples/ForgotPassword";

export const routes = [
  {
    path: "/login",
    exact: true,
    layout: DefaultLayout,
    component: Login,
  },
  {
    path: "/logout",
    exact: true,
    component: Logout,
  },
  // {
  //   path: "/upload-media/private?token=:slug",
  //   // exact: true,
  //   layout: InsideLayout,
  //   component: UploadPrivate,
  // },
  {
    path: "/upload-media/files",
    exact: true,
    // layout: DefaultLayout,
    component: UploadMedia,
  },
  /* {
    path: "/forgot-password",
    exact: true,
    layout: DefaultLayout,
    component: ForgotPassword
  }, */
];

export const privateroutes = [
  {
    path: "/",
    exact: true,
    layout: InsideLayout,
    component: Enterprise,
  },
  {
    path: "/templates",
    exact: true,
    layout: InsideLayout,
    component: Index,
  },
  {
    path: "/templates/:slug",
    exact: true,
    layout: DefaultLayout,
    component: Templates,
  },
  // {
  //   path: "/upload-media/files",
  //   exact: true,
  //   layout: InsideLayout,
  //   component: UploadMedia,
  // },
  // {
  //   path: "/profile",
  //   component: Profile,
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   layout: DefaultLayout,
  //   component: () => <Redirect to="/dashboard" />
  // },
  /* {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  } */
];
