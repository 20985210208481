import { authHeader, enterpriseHeader } from "../_helpers";
import md5 from "crypto-js/md5";

export const enterpriseService = {
  getEnterpriseList,
  getTemplatesbyEnterprise,
  getCuratedTemplates,
  cloneTemplate,
  changeCategoryService,
  createTemplate,
};

function getEnterpriseList() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/enterprise/list`,
    requestOptions
  ).then(handleResponse);
}

function getTemplatesbyEnterprise(id) {
  const newID = md5(id.toString());
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      ...enterpriseHeader(),
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/enterprise/templates/${newID}`,
    requestOptions
  ).then(handleResponse);
}

function getCuratedTemplates(id) {
  const newID = md5(id.toString());
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      ...enterpriseHeader(),
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/templates/${newID}`,
    requestOptions
  ).then(handleResponse);
}

function createTemplate(data) {
  // const newID = md5(id.toString());
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      ...enterpriseHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/templates`,
    requestOptions
  ).then(handleResponse);
}

function cloneTemplate(id) {
  // const newID = md5(id.toString());
  const requestOptions = {
    method: "GET",
    headers: {
      ...authHeader(),
      ...enterpriseHeader(),
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/templates/clone/${id}`,
    requestOptions
  ).then(handleResponse);
}

function changeCategoryService(id, data) {
  // const newID = md5(id.toString());
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      ...enterpriseHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/templates/change-category/${id}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("profile");
  localStorage.removeItem("enterprise");
  localStorage.removeItem("category");
}

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      window.location.reload(true);
    } else {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        // if (response.status === 401) {
        //     // auto logout if 401 response returned from api
        //     logout();
        //     window.location.reload(true);
        // }
        // if (data && data.errors) {
        //
        // }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    }
  });
}
