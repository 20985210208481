import { combineReducers } from "redux";
import category from "./template/category.reducer";
import category_change from "./template/category_change.reducer";
import clone from "./template/clone.reducer";
import templates from "./template/templates.reducer";
import template_detail from "./template/template.reducer";
import templatesByEnterprise from "./template/templatesByEnterprise.reducer";
import curatedTemplates from "./template/curatedTemplates.reducer";

export const template = combineReducers({
  category,
  category_change,
  clone,
  templates,
  template_detail,
  templatesByEnterprise,
  curatedTemplates,
});
