import { userConstants } from '../_constants';

var storageData = localStorage.getItem('profile');
let item = [];
if(storageData !== null && storageData !== ''){
  item = JSON.parse(localStorage.getItem('profile'));
}

const initialState = {
  items: item ? item : [],
  loading: false,
  loading_bkc: false,
  edit_mode:{
    name : false
  },
  error: null
};

export function profile(state = initialState, action) {
  switch (action.type) {
    case userConstants.PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.profile
      };
    case userConstants.PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: []
      };
    case userConstants.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.profile,
        edit_mode:{
          ...state.edit_mode,
          name : false
        }
      };
    case userConstants.PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.ENTERPRISE_JOBS_USAGE_UPDATE:
      state.items.company.total_enterprise_jobs = action.total_enterprise_jobs;
      return {
        ...state,
        loading: false,
        items: state.items
      };
    case userConstants.BRAND_COLOR_UPDATE_REQUEST:
      return {
        ...state,
        loading_bkc: true,
        error: null
      };
    case userConstants.BRAND_COLOR_UPDATE_SUCCESS:
      return {
        ...state,
        loading_bkc: false,
        items: action.payload.profile
      };
    case userConstants.BRAND_COLOR_UPDATE_FAILURE:
      return {
        ...state,
        loading_bkc: false,
        error: action.error
      };
    case userConstants.PROFILE_EDIT_CHANGE_MODE:
      return {
        ...state,
        edit_mode:{...state.edit_mode,...action.data}
      };
    default:
      return state
  }
}
