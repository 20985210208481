import React, { useState, useEffect, useRef } from 'react'
import { Button, Col } from 'shards-react';
import { DefaultLayout as Layout } from 'layouts';
import _ from "lodash";
import { toast } from 'react-toastify';
import { commonActions } from '../../_actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import jwt from 'jwt-decode' // import dependency
import { ButtonLoading } from '../../components/Loading';

function UploadMedia(props) {
    const { profile, user } = props
    const router = useHistory()
    const inputRef = useRef(null);
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (_.isEmpty(user.items)) {
            router.push("/upload-media/not-exist")
        } else if (jwt(user.items.token).exp < Date.now() / 1000) {
            localStorage.removeItem('user');
            router.push("/upload-media/files")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileChange = async (e) => {
        e.preventDefault();
        let resultFileType = await checkFileType(e)
        if (!resultFileType) {
            let result = await checkFiles(e);
            if (!result) {
                let formData = [];
                for (let i = 0; i < e.target.files.length; i++) {
                    formData.push(e.target.files[i]);
                }
                setFile(formData)
            } else {
                toast.error("File size should be less than 4 MB")
                inputRef.current.value = null;
            }
        } else {
            toast.error("Only Image and Video files are allowed.");
            inputRef.current.value = null;
        }
    };

    const checkFileType = async (e) => {
        let files = e.target.files;
        let error = false;
        if (files) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (file.type.startsWith('image/') || file.type.startsWith('video/')) {
                } else error = true;
            }
            return error;
        }
    }

    const checkFiles = async (e) => {
        let files = e.target.files;
        let error = false;
        if (files) {
            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                if (file.size > 4000000) error = true
            }
            return error;
        }
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (file) {
            setLoading(true)
            props.uploadMediaEditor(file, profile.id, { callback: function () { resetFileInput() } })
        } else toast.error("Please select a file")
    };

    const resetFileInput = (e) => {
        inputRef.current.value = null;
        setLoading(false)
    };

    return (
        <Layout noLoginBtn={true}>
            <Col style={{ height: "100vh" }} className="flex-align-center flex-column">
                <div>
                    <h2>Welcome{" "}<strong>{user.items.first_name + " " + user.items.last_name} </strong> </h2>
                </div>
                <form onSubmit={handleSubmit} className='upload-file-form'>
                    <input
                        id='fileUpload'
                        type='file'
                        ref={inputRef}
                        multiple
                        className="upload-file-input"
                        accept='image/jpg, image/svg, image/png, image/jpeg'
                        onChange={(e) => handleFileChange(e)}
                    />
                    <label htmlFor='fileUpload' className='upload-file-btn'>
                        <Button size={"lg"} className='btn btn-primary' disabled={loading}>
                            {loading ? <ButtonLoading showText={true} /> : "Upload Files"}
                        </Button>
                    </label>
                </form>
            </Col>
        </Layout>
    )
}

const mapState = (state) => ({
    profile: state.profile.items,
    user: state.uploadMediaUser
});

const actionCreators = {
    uploadMediaEditor: commonActions.uploadMediaEditor
};

export default connect(mapState, actionCreators)(UploadMedia);