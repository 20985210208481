/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Templates from "./IndexSections/Templates";

class TemplateCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.params.slug,
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
        <main ref="main">
          <Templates slug={this.state.slug}/>
        </main>
    );
  }
}

export default TemplateCategory;
