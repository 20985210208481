/*eslint-disable*/
import React from "react";
// reactstrap components
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
const date = new Date().getFullYear();

class SimpleFooter extends React.Component {
  render() {
    const { menuItems, copyright } = this.props;
    return (
      <>
        <footer className="bg-white py-5">
          <Container>
            <Row className="wow fadeInUp">
              <Col
                lg={4}
                className="text-center text-lg-left d-flex align-items-center"
              >
                {copyright}
              </Col>
              <Col lg={4} className="text-center d-flex justify-content-center">
                {menuItems.map((item, idx) => (
                  <NavLink key={idx} href={item.href} target="_blank">
                    {item.title}
                  </NavLink>
                ))}
              </Col>
              <Col
                lg={4}
                className="text-center d-flex align-items-center justify-content-end text-lg-right"
              >
                <div className="footericon">
                  <a
                    href="https://twitter.com/cliquify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/cliquify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/cliquify-inc/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

SimpleFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright info.
   */
  copyright: PropTypes.string,
};

SimpleFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © " + date + " Cliquify",
  menuItems: [
    {
      title: "Privacy & Cookie Policy",
      href: `${process.env.REACT_APP_WEB_URL}page/privacy-policy`,
    },
    {
      title: "Terms of Service",
      href: `${process.env.REACT_APP_WEB_URL}page/terms-conditions`,
    },
  ],
};

export default SimpleFooter;
