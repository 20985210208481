import { enterpriseConstants } from "../_constants";
import { enterpriseService, service } from "../_services";
// import { authHeader } from "../_helpers";
import md5 from "crypto-js/md5";
import { toast } from "react-toastify";
// import _ from "lodash";

export const enterpriseActions = {
  getEnterpriseList,
  brandingSelect,
  getTemplatesbyEnterprise,
  getCuratedTemplates,
  createTemplate,
  cloneTemplate,
  changeTemplateCategory,
  deleteTemplate,
  changeTemplateGlobal,
  categorySelect,
};

function getEnterpriseList() {
  return (dispatch) => {
    dispatch(request());
    enterpriseService.getEnterpriseList().then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enterpriseConstants.GET_ENTERPRISE_LIST_REQUEST };
  }
  function success(items) {
    return { type: enterpriseConstants.GET_ENTERPRISE_LIST_SUCCESS, items };
  }
  function failure(error) {
    return { type: enterpriseConstants.GET_ENTERPRISE_LIST_FAILURE, error };
  }
}

function brandingSelect(data) {
  return (dispatch) => {
    localStorage.setItem("enterprise", JSON.stringify(data));
    dispatch(success(data));
  };

  function success(detail) {
    return { type: enterpriseConstants.BRAND_SELECT_SUCCESS,detail};
  }
}

function categorySelect(data) {
  return (dispatch) => {
    localStorage.setItem("category", JSON.stringify(data));
    dispatch(success(data));
  };

  function success(detail) {
    return { type: enterpriseConstants.CATEGORY_SELECT_SUCCESS,detail};
  }
}

function getTemplatesbyEnterprise(id) {
  return (dispatch) => {
    dispatch(request());

    enterpriseService.getTemplatesbyEnterprise(id).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enterpriseConstants.GET_TEMPLATES_BY_ENTERPRISE_REQUEST };
  }
  function success(items) {
    return {
      type: enterpriseConstants.GET_TEMPLATES_BY_ENTERPRISE_SUCCESS,
      items,
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.GET_TEMPLATES_BY_ENTERPRISE_FAILURE,
      error,
    };
  }
}

function getCuratedTemplates(id) {
  return (dispatch) => {
    dispatch(request());

    enterpriseService.getCuratedTemplates(id).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: enterpriseConstants.GET_CURATED_TEMPLATES_REQUEST };
  }
  function success(items) {
    return {
      type: enterpriseConstants.GET_CURATED_TEMPLATES_SUCCESS,
      items,
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.GET_CURATED_TEMPLATES_FAILURE,
      error,
    };
  }
}

function createTemplate(data) {
  return (dispatch) => {
    dispatch(request());

    enterpriseService.createTemplate(data).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(response.message);
        let newId = md5(response.data.id.toString());
        window.location.href = process.env.REACT_APP_BASE_URL + "/editor/" + newId;
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.TEMPLATE_CREATE_REQUEST };
  }
  function success(detail) {
    return { type: enterpriseConstants.TEMPLATE_CREATE_SUCCESS, detail };
  }
  function failure(error) {
    return { type: enterpriseConstants.TEMPLATE_CREATE_FAILURE, error };
  }
}

function cloneTemplate(id) {
  return (dispatch) => {
    dispatch(request());

    enterpriseService.cloneTemplate(id).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(response.message);
        let newId = md5(response.data.id.toString());
        window.location.href = process.env.REACT_APP_BASE_URL + "/editor/" + newId;
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.TEMPLATE_CLONE_REQUEST };
  }
  function success(detail) {
    return { type: enterpriseConstants.TEMPLATE_CLONE_SUCCESS, detail };
  }
  function failure(error) {
    return { type: enterpriseConstants.TEMPLATE_CLONE_FAILURE, error };
  }
}

function changeTemplateCategory(id, data) {
  return (dispatch) => {
    dispatch(request());
    let newId = md5(id.toString());

    enterpriseService.changeCategoryService(newId, data).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_REQUEST };
  }
  function success(detail) {
    return {
      type: enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_SUCCESS,
      detail,
    };
  }
  function failure(error) {
    return {
      type: enterpriseConstants.TEMPLATE_CHANGE_CATEGORY_FAILURE,
      error,
    };
  }
}

function deleteTemplate(detail) {
  return (dispatch) => {
    dispatch(request());
    let id = md5(detail.id.toString());

    const url = "templates/" + id;
    const method = "DELETE";
    service.sendAuth(url, method).then(
      (response) => {
        dispatch(success(detail.id));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.TEMPLATE_DELETE_REQUEST };
  }
  function success(id) {
    return { type: enterpriseConstants.TEMPLATE_DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: enterpriseConstants.TEMPLATE_DELETE_FAILURE, error };
  }
}

function changeTemplateGlobal(id, data, extra) {
  return (dispatch) => {
    dispatch(request());

    const url = `templates/change-global/${id}`;
    const method = "POST";

    service.sendAuth(url, method, data).then(
      (response) => {
        dispatch(success({ ...extra, ...data }));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: enterpriseConstants.TEMPLATE_CHANGE_GLOBAL_REQUEST };
  }
  function success(detail) {
    return { type: enterpriseConstants.TEMPLATE_CHANGE_GLOBAL_SUCCESS, detail };
  }
  function failure(error) {
    return { type: enterpriseConstants.TEMPLATE_CHANGE_GLOBAL_FAILURE, error };
  }
}
