import { userConstants } from '../_constants';

const initialState = {
  loading: false,
  error: null
};

export function forgotpass(state = initialState, action) {
  switch (action.type) {
    case userConstants.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state
  }
}
