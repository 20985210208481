import { enterpriseConstants, userConstants } from "../_constants";
let Enterprise = JSON.parse(localStorage.getItem("enterprise"));

const initialState = {
  loggedIn: true,
  selected: Enterprise,
  company_selected: Enterprise ? Enterprise.slug  : '',
};

export function brandingSelect(state = initialState, action) {
  switch (action.type) {
    case enterpriseConstants.BRAND_SELECT_REQUEST:
      return {};
    case enterpriseConstants.BRAND_SELECT_SUCCESS:
      return {
        ...state,
        selected: action.detail,
        company_selected: action.detail.slug,
      };
    case enterpriseConstants.BRAND_SELECT_FAILURE:
      return {};
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        selected: "",
      };
    case userConstants.LOGOUT:
      localStorage.removeItem("Enterprise");
      return {
        ...state,
        loggedIn: false,
        selected: "",
      };
    default:
      return state;
  }
}
