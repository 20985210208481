/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

// reactstrap components
import { NavLink } from "reactstrap";

//shards-react components

import { Container, Navbar } from "shards-react";

const LoginButton = () => {
  return (
    <NavLink className="" to="/login" tag={Link}>
      <a
        className={`btn btn-md ml-3 loginbtn d-lg-block${
          window.location.pathname === "/login" ? " active" : ""
        }`}
      >
        Log in
      </a>
    </NavLink>
  );
};

// const LogoutButton = (props) => {
//   const [visible, setVisible] = useState(false);

//   const toggleUserActions = () => {
//     setVisible(!visible);
//   };

//   return (
//     <NavItem
//       tag={Dropdown}
//       caret
//       toggle={toggleUserActions}
//       style={{ float: "right" }}
//     >
//       <DropdownToggle
//         caret
//         tag="a"
//         className="text-nowrap pr-3 pl-1 nav-link py-2"
//       >
//         <img
//           className="user-avatar rounded-circle mr-2"
//           src={require("assets/img/avatars/user.svg").default}
//           alt="User Avatar"
//           style={{ filter: "grayscale(100%)", width: "40px", height: "40px" }}
//         />
//         <span className="nav-link-inner--text ml-2 text-default">
//           {props.first_name} {props.last_name}
//         </span>
//       </DropdownToggle>
//       <Collapse tag={DropdownMenu} right small open={visible}>
//         <NavLink
//           className="nav-link d-flex align-items-center p-0 pointer"
//           style={{ color: "rgb(255, 1, 125)" }}
//           to="/logout"
//           tag={Link}
//         >
//           <i className="material-icons mx-2 text-dark">&#xE879;</i> Logout
//         </NavLink>
//       </Collapse>
//     </NavItem>
//   );
// };

class OuterNavbar extends React.Component {
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    // const { user, profile } = this.props;
    const classes = classNames("main-navbar", "bg-white", "fixed-top");
    const noLoginBtn = this.props.noLoginBtn
    console.log("noLoginBtn", noLoginBtn);

    return (
      <>
        <div className={classes}>
          <Navbar
            className=" navbar-light bg-default headroom py-0 px-3"
            id="navbar-main"
          >
            <Container fluid className="p-0">
              <Link to={"/"}>
                <img
                  src={require("../../../assets/img/brand/logo.png").default}
                  alt="logo"
                  style={{ marginTop: "3px", height: "45px", width: "167px" }}
                />
              </Link>
              {!noLoginBtn && <LoginButton />}
            </Container>
          </Navbar>
        </div>
      </>
    );
  }
}

function mapState(state) {
  return {
    profile: state.profile.items,
    user: state.authentication.user,
  };
}

export default connect(mapState)(OuterNavbar);
