import { userConstants } from '../../_constants';

const initialState = {
  items: [],
  loading: false,
  loading_submit: false,
  fetched: false,
  error: null
};

export default function templates(state = initialState, action) {
  switch (action.type) {
    case userConstants.TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case userConstants.TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        items: action.items
      };
    case userConstants.TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        items: []
      };
    case userConstants.TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        items: [...state.items,action.detail]
      };
    case userConstants.TEMPLATE_DELETE_REQUEST:
      return {
        ...state,
        loading_submit: true,
        error: null
      };
    case userConstants.TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        loading_submit: false,
        items: state.items.filter(item => item.id !== action.id)
      };
    case userConstants.TEMPLATE_DELETE_FAILURE:
      return {
        ...state,
        loading_submit: false,
        error: action.error
      };
    case userConstants.TEMPLATE_CHANGE_GLOBAL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => item.id === action.detail.id ? { ...item, set_global: action.detail.set_global} : item)
      };
    case userConstants.TEMPLATE_CHANGE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(item => item.id === action.detail.id ? action.detail : item)
      };
    default:
      return state
  }
}
