import { authHeader } from "../_helpers";

export const userService = {
  login,
  logout,
  register,
  forgotPassword,
  getAll,
  getOne,
  getById,
  update,
  changePassword,
  changeCompanyLogo,
  updateCompanyColor,
  onboard_company,
  getPricing,
  getFaq,
  getMembership,
  /* getCardCategories,
    getCardCategoriesImages, */
  delete: _delete,
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.removeItem("GetStartedShow");
      return user;
    });
}

function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/forgot-password`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("profile");
  localStorage.removeItem("enterprise");
  localStorage.removeItem("category");
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/users`, requestOptions).then(
    handleResponse
  );
}

function getOne() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/profile`, requestOptions)
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function getById(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function register(user) {
  var postData = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    password: user.password,
  };
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/signup`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.removeItem("profile");
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

function onboard_company(postData) {
  let formData = new FormData();
  formData.append("company_name", postData.company_name);
  formData.append("people", postData.people);
  formData.append("timezone", JSON.stringify(postData.timezone));
  formData.append("timezone_index", postData.timezone_index);
  formData.append("user_post_as", postData.user_post_as);
  if (postData.logo) {
    formData.append("logo", postData.logo);
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/onboard/company`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function update(user) {
  const requestOptions = {
    //method: 'PUT',
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/update-user-profile`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function changeCompanyLogo(postData) {
  let formData = new FormData();
  formData.append("logo", postData.logo);

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/onboard/company/logo`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function updateCompanyColor(postData) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(postData),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/brand-kit/colors`,
    requestOptions
  )
    .then(handleResponse)
    .then((profile) => {
      localStorage.setItem("profile", JSON.stringify(profile.data));
      return profile;
    });
}

function changePassword(user) {
  const requestOptions = {
    //method: 'PUT',
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/change-password`,
    requestOptions
  ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getPricing() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/subscription-plans`,
    requestOptions
  ).then(handleResponse);
}

function getFaq() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetch(`${process.env.REACT_APP_API_URL}/faqs`, requestOptions).then(
    handleResponse
  );
}

function getMembership() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/customer/current/memberships`,
    requestOptions
  ).then(handleResponse);
}

/* function getCardCategories() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/card-category`, requestOptions).then(handleResponse);
}

function getCardCategoriesImages(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${process.env.REACT_APP_API_URL}/card-category/images/${id}`, requestOptions).then(handleResponse);
} */

function handleResponse(response) {
  return response.text().then((text) => {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      logout();
      window.location.reload(true);
    } else {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        // if (response.status === 401) {
        //     // auto logout if 401 response returned from api
        //     logout();
        //     window.location.reload(true);
        // }
        // if (data && data.errors) {
        //
        // }

        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    }
  });
}
