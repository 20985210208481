import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  Col,
  // Container,
  // Form,
  // Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "shards-react";
import { enterpriseActions } from "../../../_actions";
// import { Modal } from "reactstrap";

function NavbarEnterprise(props) {
  const { brandSelect, enterpriseListing } = props;

  const { detail: enterpriseList } = enterpriseListing;

  const [selectedEnterprise, setSelectedEnterprise] = useState(
    brandSelect.selected
  );
  const [enterpriseSelectModal, setenterpriseSelectModal] = useState(false);
  const [enterpriseDropdown, setenterpriseDropdown] = useState(false);

  const Enterprise = JSON.parse(localStorage.getItem("enterprise"));

  // const handleChangeEnterprise = (e) => {
  //   const item = enterpriseList.filter((item) => item.slug === e.target.value);
  //   setSelectedEnterprise(item[0]);
  //   // toggleModal("enterpriseModal");
  // };

  const toggleModal = (modal) => {
    if (modal === "enterpriseModal") {
      setenterpriseSelectModal(!enterpriseSelectModal);
    }
  };

  const toggle = () => {
    setenterpriseDropdown(!enterpriseDropdown);
  };

  const handleCheck = (item) => {
    setSelectedEnterprise(item);

    if (!_.isEmpty(selectedEnterprise)) {
      toggleModal("enterpriseModal");
      props.brandingSelectAction(selectedEnterprise);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(selectedEnterprise)) {
      props.brandingSelectAction(selectedEnterprise);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnterprise]);

  return (
    <>
      <Col className="flex-align-center">
        <span className="outline navBar-button" color="primary">
          {Enterprise.name}
        </span>
        {/* <span
          className="pointer text-dark"
          onClick={() => toggleModal("enterpriseModal")}
        >
          {" "}
          (Change Company)
        </span> */}
        <Dropdown open={enterpriseDropdown} toggle={() => toggle()}>
          <DropdownToggle
            style={{
              background: "none",
              color: "black",
              border: "none",
              boxShadow: "none",
              padding: "0",
            }}
          >
            ( Change Company )
          </DropdownToggle>
          <DropdownMenu className="navbar-Dropdown" right>
            {!_.isEmpty(enterpriseList) &&
              enterpriseList.map((item, index) => {
                return (
                  <DropdownItem
                    key={index}
                    value={item.slug}
                    onClick={() => handleCheck(item)}
                  >
                    {item.name}
                  </DropdownItem>
                );
              })}
          </DropdownMenu>
        </Dropdown>
      </Col>
      {/* {enterpriseSelectModal && (
        <Modal
          className="modal-md modal-dialog-centered"
          isOpen={enterpriseSelectModal}
          toggle={() => toggleModal("enterpriseModal")}
        >
          <div className="modal-header p-3">
            <h5 className="modal-title" id="enterpriseModalLabel">
              Change Enterprise
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal("enterpriseModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Container className="py-4">
            <Form role="form">
              <select
                className="custom-select mb-3"
                value={
                  !_.isEmpty(selectedEnterprise) ? selectedEnterprise.slug : ""
                }
                onChange={(e) => handleChangeEnterprise(e)}
              >
                <option defaultValue disabled>
                  Select Enterprise
                </option>
                {!_.isEmpty(enterpriseList) &&
                  enterpriseList.map((item, index) => {
                    return (
                      <option key={index} value={item.slug}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="button"
                  size="lg"
                  onClick={() => handleCheck()}
                >
                  Select
                </Button>
              </div>
            </Form>
          </Container>
        </Modal>
      )} */}
    </>
  );
}

function mapState(state) {
  return {
    categories: state.template.category,
    templates: state.template.templates,
    category_change: state.template.category_change,
    clone: state.template.clone,
    enterpriseTemplates: state.template.templatesByEnterprise,
    curatedTemplates: state.template.curatedTemplates,
    enterpriseListing: state.enterpriseList,
    brandSelect: state.brandingSelect,
  };
}

const actionCreators = {
  getEnterpriseList: enterpriseActions.getEnterpriseList,
  createTemplate: enterpriseActions.createTemplate,
  changeGlobal: enterpriseActions.changeTemplateGlobal,
  deleteTemplate: enterpriseActions.deleteTemplate,
  changeCategory: enterpriseActions.changeTemplateCategory,
  getTemplatesbyEnterprise: enterpriseActions.getTemplatesbyEnterprise,
  getCuratedTemplates: enterpriseActions.getCuratedTemplates,
  brandingSelectAction: enterpriseActions.brandingSelect,
  cloneTemplate: enterpriseActions.cloneTemplate,
};

export default connect(mapState, actionCreators)(NavbarEnterprise);
