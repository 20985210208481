import { commonConstants } from "../_constants";

var storageData =
    typeof window !== "undefined" ? localStorage.getItem("user") : [];
let item = [];
if (storageData !== null && storageData !== "") {
    item =
        typeof window !== "undefined"
            ? JSON.parse(localStorage.getItem("user"))
            : [];
}

const initialState = {
    items: item ? item : [],
    loading: false,
    error: null,
};

export function uploadMediaUser(state = initialState, action) {
    switch (action.type) {
        case commonConstants.VERIFY_REDIRECT_STATUS:
            return {
                ...state,
                items: action.data
            };
        default:
            return state;
    }
}
