import { userConstants } from "../_constants";
import { service, userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { toast } from "react-toastify";
// import md5 from "crypto-js/md5";

export const userActions = {
  login,
  logout,
  register,
  forgotPassword,
  getAll,
  getOne,
  editProfile,
  changeProfileEditMode,
  changePassword,
  uploadBrandLogos,
  deleteBrandLogo,
  getBrandLogos,
  uploadBrandImages,
  deleteBrandImage,
  getBrandImages,
  uploadBrandFont,
  deleteBrandFont,
  getBrandFonts,
  emailVerification,
  resendEmailVerification,
  delete: _delete,
  getTemplates,
  getTemplateCategories,
  getTemplatesOverview,
  getTemplatesByCategory,
};

function login(username, password, from) {
  return (dispatch) => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        dispatch(request_profile());
        dispatch(alertActions.success(user.message));

        userService.getOne().then(
          (profile) => {
            dispatch(success_profile(profile.data));

            // go to state.from if set before
            if (from) {
              history.replace(from);
            } else {
              history.replace("/");
            }
          },
          (error) => dispatch(failure_profile(error.toString()))
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
  function request_profile() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success_profile(profile) {
    return { type: userConstants.PROFILE_SUCCESS, payload: { profile } };
  }
  function failure_profile(error) {
    return { type: userConstants.PROFILE_FAILURE, payload: { error } };
  }
}

function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request());
    userService.forgotPassword(email).then(
      (response) => {
        dispatch(success());
        //dispatch(alertActions.success('You are logged in successfully.'));
        history.replace("/login");
        dispatch(alertActions.success(response.message));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.FORGOT_PASSWORD_REQUEST };
  }
  function success() {
    return { type: userConstants.FORGOT_PASSWORD_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.FORGOT_PASSWORD_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  window.location.pathname = "/";
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success(user));
        dispatch(request_profile());
        dispatch(success_user(user));
        //history.push('/login');

        userService.getOne().then(
          (profile) => {
            dispatch(success_profile(profile.data));
            history.push("/onboard/company");
            dispatch(alertActions.success(user.message));
          },
          (error) => dispatch(failure_profile(error.toString()))
        );
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function success_user(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
  function request_profile() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success_profile(profile) {
    return { type: userConstants.PROFILE_SUCCESS, payload: { profile } };
  }
  function failure_profile(error) {
    return { type: userConstants.PROFILE_FAILURE, payload: { error } };
  }
}

function getOne() {
  return (dispatch) => {
    dispatch(request());

    userService.getOne().then(
      (profile) => dispatch(success(profile.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.PROFILE_REQUEST };
  }
  function success(profile) {
    return { type: userConstants.PROFILE_SUCCESS, payload: { profile } };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_FAILURE, payload: { error } };
  }

  /*const request = () => ({
      type: userConstants.PROFILE_REQUEST
    });

    const success = profile => ({
      type: userConstants.PROFILE_SUCCESS,
      payload: { profile }
    });

    const failure = error => ({
      type: userConstants.PROFILE_FAILURE,
      payload: { error }
    });*/
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}

function editProfile(data) {
  return (dispatch) => {
    dispatch(request());

    userService.update(data).then(
      (response) => {
        dispatch(success(response.data));
        //dispatch(success_profile(response.data));
        //history.push('/onboard/social');
        //dispatch(alertActions.success(' ✓ Profile Information update successfully.'));
        toast.success(" ✓ Profile Information update successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        //dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.PROFILE_UPDATE_REQUEST };
  }
  //function success(response) { return { type: userConstants.PROFILE_UPDATE_SUCCESS, response } }
  function success(profile) {
    return { type: userConstants.PROFILE_UPDATE_SUCCESS, payload: { profile } };
  }
  function failure(error) {
    return { type: userConstants.PROFILE_UPDATE_FAILURE, error };
  }
}

function changeProfileEditMode(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return { type: userConstants.PROFILE_EDIT_CHANGE_MODE, data };
  }
}

function changePassword(data) {
  return (dispatch) => {
    dispatch(request());

    userService.changePassword(data).then(
      (response) => {
        dispatch(success());
        //dispatch(alertActions.success(' ✓ Profile Password changed successfully.'));
        toast.success(" ✓ Profile Password changed successfully.");
      },
      (error) => {
        dispatch(failure(error.toString()));
        //dispatch(alertActions.error(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.CHANGE_PASSWORD_REQUEST };
  }
  function success(response) {
    return { type: userConstants.CHANGE_PASSWORD_SUCCESS, response };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASSWORD_FAILURE, error };
  }
}

function uploadBrandLogos(data) {
  return (dispatch) => {
    dispatch(request());

    let formData = new FormData();
    for (let i = 0; i < data.images.length; i++) {
      formData.append(`images[]`, data.images[i]);
    }

    const url = "brand-kit/logo";
    service.sendAuthForm(url, formData).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.BRAND_LOGO_UPLOAD_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_LOGO_UPLOAD_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_LOGO_UPLOAD_FAILURE, error };
  }
}

function getBrandLogos() {
  return (dispatch) => {
    dispatch(request());

    const url = "brand-kit/logo";
    service.getAuth(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_LOGO_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_LOGO_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_LOGO_FAILURE, error };
  }
}

function deleteBrandLogo(data) {
  return (dispatch) => {
    dispatch(request());

    var id = data.id;
    const url = "brand-kit/logo/" + id;
    const method = "DELETE";

    service.sendAuth(url, method, data).then(
      (response) => dispatch(success(id)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_LOGO_DELETE_REQUEST };
  }
  function success(id) {
    return { type: userConstants.BRAND_LOGO_DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: userConstants.BRAND_LOGO_DELETE_FAILURE, error };
  }
}

function uploadBrandImages(data) {
  return (dispatch) => {
    dispatch(request());

    let formData = new FormData();
    for (let i = 0; i < data.images.length; i++) {
      formData.append(`images[]`, data.images[i]);
    }

    const url = "brand-kit/images";
    service.sendAuthForm(url, formData).then(
      (response) => {
        dispatch(success(response.data));
        toast.success(response.message);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.BRAND_IMAGE_UPLOAD_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_IMAGE_UPLOAD_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_IMAGE_UPLOAD_FAILURE, error };
  }
}

function getBrandImages() {
  return (dispatch) => {
    dispatch(request());

    const url = "brand-kit/images";
    service.getAuth(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_IMAGE_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_IMAGE_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_IMAGE_FAILURE, error };
  }
}

function deleteBrandImage(data) {
  return (dispatch) => {
    dispatch(request());

    var id = data.id;
    const url = "brand-kit/images/" + id;
    const method = "DELETE";

    service.sendAuth(url, method, data).then(
      (response) => dispatch(success(id)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_IMAGE_DELETE_REQUEST };
  }
  function success(id) {
    return { type: userConstants.BRAND_IMAGE_DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: userConstants.BRAND_IMAGE_DELETE_FAILURE, error };
  }
}

function uploadBrandFont(data, params) {
  return (dispatch) => {
    dispatch(request());

    let formData = new FormData();
    formData.append("title", data.title);
    formData.append("file", data.file);

    const url = "brand-kit/fonts";
    service.sendAuthForm(url, formData).then(
      (response) => {
        dispatch(success([response.data]));
        toast.success(response.message);
        params.callback(); // <--- here the callback
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

  function request() {
    return { type: userConstants.BRAND_FONT_UPLOAD_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_FONT_UPLOAD_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_FONT_UPLOAD_FAILURE, error };
  }
}

function getBrandFonts() {
  return (dispatch) => {
    dispatch(request());

    const url = "brand-kit/fonts";
    service.getAuth(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_FONT_LIST_REQUEST };
  }
  function success(items) {
    return { type: userConstants.BRAND_FONT_LIST_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.BRAND_FONT_LIST_FAILURE, error };
  }
}

function deleteBrandFont(data) {
  return (dispatch) => {
    dispatch(request());

    var id = data.id;
    const url = "brand-kit/fonts/" + id;
    const method = "DELETE";

    service.sendAuth(url, method, data).then(
      (response) => dispatch(success(id)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.BRAND_FONT_DELETE_REQUEST };
  }
  function success(id) {
    return { type: userConstants.BRAND_FONT_DELETE_SUCCESS, id };
  }
  function failure(error) {
    return { type: userConstants.BRAND_FONT_DELETE_FAILURE, error };
  }
}

function emailVerification(code) {
  return (dispatch) => {
    dispatch(request());

    const url = "verify-email/" + code;
    service.getSimple(url).then(
      (response) => dispatch(success(response.message)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.EMAIL_VERIFICATION_REQUEST };
  }
  function success(message) {
    return { type: userConstants.EMAIL_VERIFICATION_SUCCESS, message };
  }
  function failure(error) {
    return {
      type: userConstants.EMAIL_VERIFICATION_FAILURE,
      payload: { error },
    };
  }
}

function resendEmailVerification() {
  return (dispatch) => {
    dispatch(request());

    const url = "re-verify-email";
    service.getAuth(url).then(
      function (response) {
        toast.success(response.message);
        return dispatch(success());
      },
      (error) => {
        toast.error(error.toString());
        return dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: userConstants.RESEND_EMAIL_VERIFICATION_REQUEST };
  }
  function success() {
    return { type: userConstants.RESEND_EMAIL_VERIFICATION_SUCCESS };
  }
  function failure(error) {
    return {
      type: userConstants.RESEND_EMAIL_VERIFICATION_FAILURE,
      payload: { error },
    };
  }
}

function getTemplateCategories() {
  return (dispatch) => {
    dispatch(request());

    const url = "templates/categories";
    service.getSimple(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.TEMPLATE_CATEGORY_REQUEST };
  }
  function success(items) {
    return { type: userConstants.TEMPLATE_CATEGORY_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.TEMPLATE_CATEGORY_FAILURE, error };
  }
}

function getTemplatesOverview() {
  return (dispatch) => {
    dispatch(request());

    const url = "templates/overview";
    service.getAuth(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.TEMPLATE_CAT_LIST_REQUEST };
  }
  function success(items) {
    return { type: userConstants.TEMPLATE_CAT_LIST_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.TEMPLATE_CAT_LIST_FAILURE, error };
  }
}

function getTemplatesByCategory(slug) {
  return (dispatch) => {
    dispatch(request());

    const url = "templates/categories/" + slug;
    service.getAuth(url).then(
      (response) => dispatch(success(response.data.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.TEMPLATE_BY_CAT_LIST_REQUEST };
  }
  function success(items) {
    return { type: userConstants.TEMPLATE_BY_CAT_LIST_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.TEMPLATE_BY_CAT_LIST_FAILURE, error };
  }
}

function getTemplates() {
  return (dispatch) => {
    dispatch(request());

    const url = "templates";
    service.getAuth(url).then(
      (response) => dispatch(success(response.data)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: userConstants.TEMPLATE_LIST_REQUEST };
  }
  function success(items) {
    return { type: userConstants.TEMPLATE_LIST_SUCCESS, items };
  }
  function failure(error) {
    return { type: userConstants.TEMPLATE_LIST_FAILURE, error };
  }
}
