/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import md5 from "crypto-js/md5";
import _ from "lodash";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Modal,
  CardBody,
  CardTitle,
  CardImg,
  UncontrolledTooltip,
  PopoverBody,
  UncontrolledPopover,
} from "reactstrap";
import { FormInput } from "shards-react";

import { ButtonLoading, BlockLoading, Loading } from "../../components/Loading";
import { enterpriseActions } from "../../_actions";
import Tabs from "./Tabs";
import templateOption from "../../data/template-items";
import { service } from "_services";
const MySwal = withReactContent(Swal);
const default_custom_size = {
  category: "Custom",
  type: "custom",
  title: "Custom",
  display_width: 2560,
  display_height: 2560,
  display_unit: "px",
  display_orientation: "Custom",
  width: 2560,
  height: 2560,
  unit: "px",
  compatible_formats: "",
};

function TemplateList(props) {
  const {
    title,
    categories,
    category_change,
    clone,
    getEnterpriseList,
    enterpriseTemplates,
    getTemplatesbyEnterprise,
    getCuratedTemplates,
    curatedTemplates,
    cloneTemplate,
    brandSelect,
    enterpriseListing,
    categorySelect,
    units,
    profile
  } = props;

  const templateOptions = templateOption()
  const { detail: enterpriseList } = enterpriseListing;
  const { items: enterpriseTemps } = enterpriseTemplates;
  const { items: curatedTemps } = curatedTemplates;

  const { detail: current_category } = props.current_category;
  const [state, setState] = useState({
    catActive: current_category ? current_category.id : "all",
    createNew: false,
    catSelect: current_category ?? {},
    sizeSelect: default_custom_size,
    submitted: false,
    loadingText: "",
  });
  const [selected, setSelected] = useState({});
  const [previewModal, setpreviewModal] = useState(false);
  const [enterpriseSelectModal, setenterpriseSelectModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [categoryModal, setcategoryModal] = useState(category_change.open || false);
  const [form, setForm] = useState({ category: "" });
  const [activeTab, setActiveTab] = useState("Popular");
  const [error_text, setErrorText] = useState("");
  const dimensions_range = {
    "px": {
      min: 40,
      max: 6000
    },
    "in": {
      min: 0.56,
      max: 83.33
    },
    "mm": {
      min: 14.11,
      max: 2116.67
    }
  };

  const showTemplates = () => {
    setCreateModal(true);
    setState({
      ...state,
      createNew: true,
      sizeSelect: default_custom_size,
    });
  }

  const handleClickTemplate = (val) => {
    setState({ ...state, sizeSelect: val });
  }

  const handleCreateTemplate = () => {
    const { catActive, sizeSelect: size } = state;
    if (catActive !== "all" && !_.isEmpty(size) && error_text === '') {
      props.createTemplate({
        type: "GRAPHIC",
        cat_id: catActive,
        name: "Untitled design",
        frame: {
          dpi: 72,
          name: "Custom",
          type: "Frame",
          unit: "PIXELS",
          width: parseInt(size.width),
          height: parseInt(size.height)
        },
        custom_frame: size,
        scenes: [
          {
            layers: [
              {
                name: "Background",
                angle: 0,
                stroke: null,
                strokeWidth: 0,
                left: 0,
                top: 0,
                width: size.width,
                height: size.height,
                opacity: 1,
                originX: "left",
                originY: "top",
                scaleX: 1,
                scaleY: 1,
                type: "Background",
                flipX: false,
                flipY: false,
                skewX: 0,
                skewY: 0,
                visible: true,
                rx: 0,
                ry: 0,
                erasable: false,
                fill: "#fff",
                metadata: []
              },
            ],
            name: "Untitled design",
            metadata: {},
          },
        ],
        metadata: {
          display_width: parseFloat(size.display_width),
          display_height: parseFloat(size.display_height),
          display_unit: size.display_unit,
          display_title: size.title,
          display_category: size.category,
          compatible_formats: size.compatible_formats ?? ""
        },
        preview: "",
      });
      setState({ ...state, createNew: false, sizeSelect: default_custom_size });
    }
  }

  const handleDelete = (detail) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You’ll lose this forever",
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#FF017D",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        setState({ ...state, loadingText: "Please wait... deleting" });
        props.deleteTemplate(detail);
      }
    });
  }

  const selectCategory = (data, option) => {
    const { catActive } = state;
    const { id } = data;
    if (catActive !== id) {
      categorySelect(data);
    }
  }

  const handleEdit = (id) => {
    let newId = md5(id.toString());
    window.location.href = process.env.REACT_APP_BASE_URL + "/editor/" + newId;
  }

  const handlePreview = (detail) => {
    toggleModal("previewModal");
    setSelected(detail);
  }

  const handleChangeCategory = (data) => {
    toggleModal("categoryModal");
    setSelected(data);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  }

  const handlePostCustomSizeChange = (e) => {
    const { name, value } = e.target
    const { sizeSelect } = state;
    const { display_unit } = sizeSelect;

    const re = /^\d*(\.)?(\d{0,2})?$/;
    if (value === "" || re.test(value)) {
      let extra_name = `display_${name}`;
      let extra_value = value;
      if (value) {
        if (display_unit === "mm") {
          extra_value = (value / 25.4) * 72; // px * 25.4mm / dpi
        }
        if (display_unit === "in") {
          extra_value = value * 72;
        }
      }

      setState({
        ...state, sizeSelect: {
          ...sizeSelect,
          category: "Custom",
          type: "custom",
          title: "Custom",
          display_orientation: "Custom",
          compatible_formats: "",
          [name]: extra_value ? format2Decimals(extra_value) : 0,
          [extra_name]: value
        }
      });
    }
  }

  const handlePostCustomSizeSwap = (e) => {
    const { sizeSelect } = state;
    const { height, width, display_height, display_width } = sizeSelect;

    setState({
      ...state, sizeSelect: {
        ...sizeSelect,
        category: "Custom", title: "Custom",
        compatible_formats: "",
        height: width, width: height,
        display_height: display_width, display_width: display_height
      }
    });
  }

  const handlePostCustomUnitChange = (e) => {
    const { value } = e.target
    const { sizeSelect } = state;
    const { height, width } = sizeSelect;
    let new_width = width;
    let new_height = height;

    if (value === "mm") {
      new_height = (height * 25.4) / 72; //0.084666667
      new_width = (width * 25.4) / 72; //0.084666667
    } else if (value === "in") {
      new_height = height / 72;  //0.003333333;
      new_width = width / 72;  //0.003333333;
    }

    setState({
      ...state, sizeSelect: {
        ...sizeSelect,
        category: "Custom", title: "Custom",
        compatible_formats: "",
        display_width: format2Decimals(new_width),
        display_height: format2Decimals(new_height),
        display_unit: value,
      }
    });
  }

  function format2Decimals(str) {
    const num = parseFloat(str);
    return Math.round(num * 100) / 100;
  }

  useEffect(() => {
    const { display_height, display_width, display_unit } = state.sizeSelect;

    const height = display_height ? format2Decimals(display_height) : 0;
    const width = display_width ? format2Decimals(display_width) : 0;

    const dimensions = dimensions_range[display_unit];
    if ((height >= dimensions.min && height <= dimensions.max) && (width >= dimensions.min && width <= dimensions.max)) {
      setErrorText('')
    } else {
      //Dimensions must be at least 40px and no more than 8000px.
      setErrorText(`Dimensions must be at least ${dimensions.min}${display_unit} and no more than ${dimensions.max}${display_unit}.`)
    }
  }, [state.sizeSelect])

  useEffect(() => {
    if (_.isEmpty(enterpriseList)) {
      getEnterpriseList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseList]);

  useEffect(() => {
    const { detail: data } = props.current_category;
    if (!_.isEmpty(data)) {
      const { id } = data;
      if (id) {
        setState({
          catActive: id,
          createNew: false,
          sizeSelect: default_custom_size,
          catSelect: data,
        });
        if (!_.isEmpty(brandSelect.company_selected)) {
          getTemplatesbyEnterprise(id);
          getCuratedTemplates(id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.current_category, brandSelect.company_selected]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setState({ ...state, submitted: true });
    if (form.category) {
      let id = selected.id;
      props.changeCategory(id, form);
      toggleModal("categoryModal");
    }
  };

  const toggleModal = (modal) => {
    if (modal === "previewModal") {
      setpreviewModal(!previewModal);
    }
    if (modal === "categoryModal") {
      setcategoryModal(!categoryModal);
    }
    if (modal === "enterpriseModal") {
      setenterpriseSelectModal(!enterpriseSelectModal);
    }
    if (modal === "createModal") {
      setCreateModal(!createModal);
    }
  };

  const handleClone = (detail) => {
    let id = md5(detail.id.toString());
    cloneTemplate(id);
  };

  const handleExport = (data) => {
    const { id } = data
    let newId = md5(id.toString());
    const url = `creations/${newId}`;
    service.getAuth(url).then(
      (response) => {
        const { data } = response
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data))
        const a = document.createElement("a")
        a.href = dataStr
        a.download = "template.json"
        a.click()
      },
      (error) => {

      }
    );
  };

  const handleGlobalConfirm = (detail) => {
    const { set_global } = detail;
    let title = "";
    let value = set_global;
    if (set_global === "No") {
      title = "Make this template available";
      value = "Yes";
    } else {
      title = "Make this template unavailable";
      value = "No";
    }

    MySwal.fire({
      title: "Are you sure?",
      text: title,
      icon: "",
      showCancelButton: true,
      confirmButtonColor: "#FF017D",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.value) {
        let id = md5(detail.id.toString());
        props.changeGlobal(
          id,
          { set_global: value },
          { category: detail.cat_id, id: detail.id }
        );
      }
    });
  };

  return (
    <div className="position-relative">
      <section className="section pb-1">
        <Container fluid className="pt-lg-3 pt-3">
          {curatedTemplates.loading_submit && (
            <BlockLoading title={state.loadingText} />
          )}
          {curatedTemplates.created && (
            <BlockLoading title="Creating template... Please Wait!!" />
          )}
          {clone.loading && <BlockLoading title=" Cloning... Please Wait!" />}
          <Row>
            <Col lg="12" md="12" sm="12" className="mb-4">
              <div className="whitebox text-center">
                <h1 className="mb-2">{title}</h1>
                <ul className="sharelist d-flex clearfix">
                  {!_.isEmpty(categories.items) &&
                    categories.items.map((tc, i) => (
                      <li
                        key={`cat-${tc.id}`}
                        className={state.catActive === tc.id ? "active" : ""}
                        onClick={() => selectCategory(tc, "tag")}
                      >
                        <span className="item">{tc.title}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </Col>
          </Row>
          {enterpriseTemplates.loading && curatedTemplates.loading ? (
            <Loading />
          ) : (
            <>
              {!_.isEmpty(state.catSelect) && (
                <>
                  <Row className="mainrow">
                    <Col className="d-flex justify-content-between row-header-t">
                      <div className="page-header text-sm-left text-center text-md-left">
                        <h4 className="page-title">Curated by Cliquify</h4>
                      </div>
                    </Col>
                    <Col lg="12" md="12" sm="12">
                      <Row className="template-content-list">
                        <Col
                          className="xxlsize"
                          lg="3"
                          md="4"
                          sm="6"
                        // xl="3"
                        >
                          <Card
                            className="cardbox"
                            onClick={() => showTemplates()}
                          >
                            <div
                              className="h185 align-items-center justify-content-center d-flex"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className="material-icons"
                                style={{
                                  fontSize: "150px",
                                  color: "#33334F",
                                }}
                              >
                                add
                              </span>
                            </div>
                          </Card>
                          <CardBody>
                            <CardTitle className={"boxtitle"}>
                              New Template
                            </CardTitle>
                          </CardBody>
                        </Col>
                        {!_.isEmpty(curatedTemps) &&
                          curatedTemps.map((tmp, i) => (
                            <React.Fragment key={`row-card-${tmp.id}`}>
                              <Col
                                className="xxlsize"
                                lg="3"
                                md="4"
                                sm="6"
                                xl="3"
                              >
                                <Card className={`cardbox template-card`}>
                                  <span
                                    className="preview-btn"
                                    onClick={(e) => handlePreview(tmp)}
                                  >
                                    <span className="material-icons">
                                      zoom_in
                                    </span>
                                  </span>
                                  {profile.user_type === "admin" &&
                                    <span
                                      className="export-btn"
                                      onClick={(e) => handleExport(tmp)}
                                    >
                                      <span className="material-icons-outlined">
                                        file_download
                                      </span>
                                    </span>
                                  }
                                  <div className={"h185 text-center"}>
                                    <img
                                      src={`${tmp?.scenes[0]?.preview}?tr=w-300`}
                                    />
                                  </div>
                                  <div className="btnbox d-flex align-items-center justify-content-between">
                                    <span
                                      id={`TooltipEdit${tmp.id}`}
                                      className="pointer"
                                      onClick={(e) => handleEdit(tmp.id)}
                                    >
                                      <i className="material-icons">edit</i>
                                    </span>
                                    <span
                                      id={`TooltipClone${tmp.id}`}
                                      className="pointer"
                                      onClick={(e) => handleClone(tmp)}
                                    >
                                      <i className="material-icons">
                                        content_copy
                                      </i>
                                    </span>
                                    <span
                                      id={`TooltipCategory${tmp.id}`}
                                      className="pointer"
                                      onClick={(e) =>
                                        handleChangeCategory({
                                          id: tmp.id,
                                          category: tmp.cat_id,
                                        })
                                      }
                                    >
                                      <i className="material-icons">
                                        display_settings
                                      </i>
                                    </span>
                                    <span
                                      id={`TooltipPublish${tmp.id}`}
                                      className="pointer"
                                      onClick={(e) =>
                                        handleGlobalConfirm(tmp)
                                      }
                                    >
                                      <i className="material-icons">
                                        {tmp.set_global === "No"
                                          ? "public"
                                          : "public_off"}
                                      </i>
                                    </span>
                                    <span
                                      id={`TooltipDelete${tmp.id}`}
                                      className="pointer"
                                      onClick={(e) =>
                                        handleDelete({
                                          id: tmp.id,
                                          category: tmp.cat_id,
                                        })
                                      }
                                    >
                                      <i className="material-icons">delete</i>
                                    </span>

                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={`#TooltipEdit${tmp.id}`}
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={`#TooltipClone${tmp.id}`}
                                    >
                                      Make a Copy
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={`#TooltipCategory${tmp.id}`}
                                    >
                                      Change Category
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={`#TooltipPublish${tmp.id}`}
                                    >
                                      {tmp.set_global === "No"
                                        ? "Mark as ready"
                                        : "Mark as unavailable"}
                                    </UncontrolledTooltip>
                                    <UncontrolledTooltip
                                      delay={0}
                                      placement="top"
                                      target={`#TooltipDelete${tmp.id}`}
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </div>
                                </Card>
                                <CardBody className="px-2">
                                  <CardTitle
                                    className={
                                      "boxtitle d-flex align-items-center"
                                    }
                                  >
                                    <span>{tmp.name}</span>{" "}
                                    <i
                                      className="ml-2 material-icons-outlined"
                                      id={`PopoverSocial${tmp.id}`}
                                    >
                                      info
                                    </i>
                                  </CardTitle>
                                  <p>
                                    {tmp.metadata.display_width ?? tmp.frame.width}x{tmp.metadata.display_height ?? tmp.frame.height} {tmp.metadata.display_unit ?? "px"}{" "}
                                    <span
                                      className={`material-icons publish-status-icon ${tmp.set_global === "Yes"
                                        ? "active"
                                        : "inactive"
                                        }`}
                                    >
                                      circle
                                    </span>
                                  </p>
                                  <UncontrolledPopover
                                    placement="bottom"
                                    target={`#PopoverSocial${tmp.id}`}
                                  >
                                    <PopoverBody
                                      style={{
                                        padding: ".5rem .75rem",
                                      }}
                                    >
                                      <p
                                        className="border-bottom mb-1 pb-1"
                                        style={{ fontSize: "13px" }}
                                      >
                                        Compatible Formats
                                      </p>
                                      <div className="socialbtn">
                                        {tmp.metadata.compatible_formats && Array.isArray(tmp.metadata.compatible_formats) ? tmp.metadata.compatible_formats.map(icon => <i className={`fa fa-${icon}`}></i>)
                                          :
                                          <>
                                            <i className="fa fa-twitter"></i>
                                            <i className="fa fa-facebook"></i>
                                            <i className="fa fa-linkedin"></i>
                                          </>
                                        }
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </CardBody>
                              </Col>
                            </React.Fragment>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mainrow mt-5">
                    <Col className="d-flex justify-content-between row-header-t">
                      <div className="page-header text-sm-left text-center text-md-left">
                        <h4 className="page-title">Enterprise Templates</h4>
                      </div>
                    </Col>
                    <Col lg="12" xl="12" md="12" sm="12">
                      {!_.isEmpty(enterpriseTemps) ? (
                        <Row className="template-content-list">
                          {!_.isEmpty(enterpriseTemps) &&
                            enterpriseTemps.map((tmp, i) => (
                              <React.Fragment key={`row-card-${tmp.id}`}>
                                <Col
                                  className="xxlsize"
                                  lg="3"
                                  md="4"
                                  sm="6"
                                // xl="3"
                                >
                                  <Card className={`cardbox template-card`}>
                                    <span
                                      className="preview-btn"
                                      onClick={(e) => handlePreview(tmp)}
                                    >
                                      <span className="material-icons">
                                        zoom_in
                                      </span>
                                    </span>
                                    {profile.user_type === "admin" &&
                                      <span
                                        className="export-btn"
                                        onClick={(e) => handleExport(tmp)}
                                      >
                                        <span className="material-icons-outlined">
                                          file_download
                                        </span>
                                      </span>
                                    }
                                    <div className={"h185 text-center"}>
                                      <img
                                        // alt="0"
                                        src={`${tmp?.scenes[0]?.preview}?tr=w-300`}
                                      />
                                    </div>
                                    <span
                                      className="clone-btn pointer"
                                      id={`TooltipClone${tmp.id}`}
                                      onClick={() => handleClone(tmp)}
                                    >
                                      <div className="material-icons">
                                        content_copy
                                      </div>
                                    </span>
                                  </Card>
                                  <CardBody className="px-2">
                                    <CardTitle
                                      className={
                                        "boxtitle d-flex align-items-center"
                                      }
                                    >
                                      <span>{tmp.name}</span>{" "}
                                      <i
                                        className="ml-2 material-icons-outlined"
                                        id={`PopoverSocial${tmp.id}`}
                                      >
                                        info
                                      </i>
                                    </CardTitle>
                                    <p>
                                      {tmp.metadata.display_height ?? tmp.frame.height}x{tmp.metadata.display_width ?? tmp.frame.width} {tmp.metadata.display_unit ?? "px"}{" "}
                                      <span
                                        className={`material-icons publish-status-icon ${tmp.set_global === "Yes"
                                          ? "active"
                                          : "inactive"
                                          }`}
                                      >
                                        circle
                                      </span>
                                    </p>
                                    <UncontrolledPopover
                                      placement="bottom"
                                      target={`#PopoverSocial${tmp.id}`}
                                    >
                                      <PopoverBody
                                        style={{
                                          padding: ".5rem .75rem",
                                        }}
                                      >
                                        <p
                                          className="border-bottom mb-1 pb-1"
                                          style={{ fontSize: "13px" }}
                                        >
                                          Compatible Formats
                                        </p>
                                        <div className="socialbtn">
                                          {tmp.metadata.compatible_formats && Array.isArray(tmp.metadata.compatible_formats) ? tmp.metadata.compatible_formats.map(icon => <i className={`fa fa-${icon}`}></i>)
                                            :
                                            <>
                                              <i className="fa fa-twitter"></i>
                                              <i className="fa fa-facebook"></i>
                                              <i className="fa fa-linkedin"></i>
                                            </>
                                          }
                                        </div>
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </CardBody>
                                </Col>
                              </React.Fragment>
                            ))}
                        </Row>
                      ) : (
                        <div className="text-center mb-4">
                          No enterprise Templates in this category
                        </div>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {/*Modal*/}
          {previewModal && (
            <Modal
              className={`${window.screen.height > 961 ? "modal-lg" : "modal-md"
                } modal-dialog-centered`}
              isOpen={previewModal}
              toggle={() => toggleModal("previewModal")}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="previewModalLabel">
                  {selected?.name}
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => toggleModal("previewModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <CardImg
                  src={
                    !_.isEmpty(selected)
                      ? `${selected?.scenes[0]?.preview}?tr=w-900`
                      : ""
                  }
                  style={{
                    width: "100%",
                    border: "1px solid #eee",
                    maxHeight: "100%",
                  }}
                />
              </div>
            </Modal>
          )}
          {categoryModal && (
            <Modal
              className="modal-dialog-centered"
              isOpen={categoryModal}
              toggle={() => toggleModal("categoryModal")}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="categoryModalLabel">
                  Change Category
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => toggleModal("categoryModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <Form role="form" onSubmit={(e) => handleSubmit(e)}>
                <div className="modal-body">
                  <Row>
                    <Col md="10" className="mx-auto">
                      <FormGroup>
                        <select
                          className="form-control"
                          name="category"
                          defaultValue={selected.category}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">Select</option>
                          {categories.items.map((tc, i) => (
                            <option key={`change_c_${i}`} value={tc.id}>
                              {tc.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleModal("categoryModal")}
                  >
                    Close
                  </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={category_change.loading}
                  >
                    {category_change.loading ? (
                      <ButtonLoading showText={true} />
                    ) : (
                      `Save changes`
                    )}
                  </Button>
                </div>
              </Form>
            </Modal>
          )}

          <Modal
            className="modal-fullscreen"
            style={{ alignItems: "flex-start" }}
            isOpen={createModal}
          // toggle={() => toggleModal("createModal")}
          >
            <div className="modal-header" style={{ background: "#F5F8FA" }}>
              <h5 className="modal-title" id="createModalLabel">
                Select a size
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal("createModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <Form role="form">
              <div className="modal-body">
                <Tabs active={activeTab} changeTab={setActiveTab} />
                <Row>
                  <Col md="12" className="mx-auto">
                    <div className="post-container">
                      {activeTab === "Custom"
                        ?
                        <div className="size-container mt-4">
                          <div className="post-custom-size-form">
                            <FormGroup>
                              <span className="post-custom-size-label">Width</span>
                              <FormInput
                                name="width"
                                value={state.sizeSelect.display_width}
                                onChange={handlePostCustomSizeChange}
                              />
                            </FormGroup>
                            <span className="post-swap-button material-icons" onClick={handlePostCustomSizeSwap}>compare_arrows</span>
                            <FormGroup>
                              <span className="post-custom-size-label">Height</span>
                              <FormInput
                                name="height"
                                value={state.sizeSelect.display_height}
                                onChange={handlePostCustomSizeChange}
                              />
                            </FormGroup>
                            <FormGroup>
                              <select
                                className="post-units-dropdown form-control"
                                name="unit"
                                defaultValue={state.sizeSelect.display_unit}
                                onChange={handlePostCustomUnitChange}
                              >
                                {units.map((unit, i) => (
                                  <option key={`change_c_${i}`} value={unit}>{unit}</option>
                                ))}
                              </select>
                            </FormGroup>
                          </div>
                          {error_text && <span className="help-block text-danger">{error_text}</span>}
                        </div>
                        :
                        <ul className="sizelist mt-4" style={{ "overflowY": "auto" }}>
                          {templateOptions.filter(item => item.category === activeTab).map((val, i) => (
                            <li
                              className={
                                state.sizeSelect.type === val.type && state.sizeSelect.width === val.width
                                  ? "active"
                                  : undefined
                              }
                              key={`tmp_option_${i}`}
                              onClick={() => handleClickTemplate(val)}
                            >
                              <div className="template__box">
                                <div className={val.type}></div>
                              </div>
                              <div className="template__text">
                                <p className="template__caption">{val.title}</p>
                                <small className="template__dimensions">
                                  {val.display_width} × {val.display_height} {val.display_unit}
                                </small>
                                <small className="template__orientation">
                                  {val.display_orientation}
                                </small>
                                {val.compatible_formats &&
                                  <div className="template__compatible_formats">{val.compatible_formats.map(icon => <span className={`fa fa-${icon}`}></span>)}</div>
                                }
                              </div>
                            </li>
                          ))}
                        </ul>
                      }
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="text-center my-4">
                <Button
                  className="mr-3"
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => toggleModal("createModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  onClick={() => handleCreateTemplate()}
                  title={
                    _.isEmpty(state.sizeSelect)
                      ? `Choose a template to continue`
                      : ``
                  }
                  disabled={_.isEmpty(state.sizeSelect) || error_text}
                >
                  Create
                </Button>
              </div>
            </Form>
          </Modal>
        </Container>
      </section>
    </div>
  );
}

TemplateList.propTypes = {
  title: PropTypes.string,
  units: PropTypes.array,
}

TemplateList.defaultProps = {
  title: "Choose a category to curate new designs.",
  units: [
    "px", "mm", "in"
  ]
}

function mapState(state) {
  return {
    categories: state.template.category,
    templates: state.template.templates,
    category_change: state.template.category_change,
    clone: state.template.clone,
    enterpriseTemplates: state.template.templatesByEnterprise,
    curatedTemplates: state.template.curatedTemplates,
    enterpriseListing: state.enterpriseList,
    brandSelect: state.brandingSelect,
    current_category: state.current_category,
    profile: state.profile.items,
  };
}

const actionCreators = {
  getEnterpriseList: enterpriseActions.getEnterpriseList,
  createTemplate: enterpriseActions.createTemplate,
  changeGlobal: enterpriseActions.changeTemplateGlobal,
  deleteTemplate: enterpriseActions.deleteTemplate,
  changeCategory: enterpriseActions.changeTemplateCategory,
  getTemplatesbyEnterprise: enterpriseActions.getTemplatesbyEnterprise,
  getCuratedTemplates: enterpriseActions.getCuratedTemplates,
  brandingSelectAction: enterpriseActions.brandingSelect,
  categorySelect: enterpriseActions.categorySelect,
  cloneTemplate: enterpriseActions.cloneTemplate,
};

export default connect(mapState, actionCreators)(TemplateList);
