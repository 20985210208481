export const enterpriseConstants = {
  BRAND_SELECT_REQUEST: "ENTERPRISE_BRAND_SELECT_REQUEST",
  BRAND_SELECT_SUCCESS: "ENTERPRISE_BRAND_SELECT_SUCCESS",
  BRAND_SELECT_FAILURE: "ENTERPRISE_BRAND_SELECT_FAILURE",

  CATEGORY_SELECT_SUCCESS: "ENTERPRISE_CATEGORY_SELECT_SUCCESS",

  GET_ENTERPRISE_LIST_REQUEST: "ENTERPRISE_GET_ENTERPRISE_LIST_REQUEST",
  GET_ENTERPRISE_LIST_SUCCESS: "ENTERPRISE_GET_ENTERPRISE_LIST_SUCCESS",
  GET_ENTERPRISE_LIST_FAILURE: "ENTERPRISE_GET_ENTERPRISE_LIST_FAILURE",

  GET_TEMPLATES_BY_ENTERPRISE_REQUEST:
    "ENTERPRISE_GET_TEMPLATES_BY_ENTERPRISE_REQUEST",
  GET_TEMPLATES_BY_ENTERPRISE_SUCCESS:
    "ENTERPRISE_GET_TEMPLATES_BY_ENTERPRISE_SUCCESS",
  GET_TEMPLATES_BY_ENTERPRISE_FAILURE:
    "ENTERPRISE_GET_TEMPLATES_BY_ENTERPRISE_FAILURE",

  GET_CURATED_TEMPLATES_REQUEST: "ENTERPRISE_GET_CURATED_TEMPLATES_REQUEST",
  GET_CURATED_TEMPLATES_SUCCESS: "ENTERPRISE_GET_CURATED_TEMPLATES_SUCCESS",
  GET_CURATED_TEMPLATES_FAILURE: "ENTERPRISE_GET_CURATED_TEMPLATES_FAILURE",

  TEMPLATE_CREATE_REQUEST: "ENTERPRISE_TEMPLATE_CREATE_REQUEST",
  TEMPLATE_CREATE_SUCCESS: "ENTERPRISE_TEMPLATE_CREATE_SUCCESS",
  TEMPLATE_CREATE_FAILURE: "ENTERPRISE_TEMPLATE_CREATE_FAILURE",

  TEMPLATE_CLONE_REQUEST: "ENTERPRISE_TEMPLATE_CLONE_REQUEST",
  TEMPLATE_CLONE_SUCCESS: "ENTERPRISE_TEMPLATE_CLONE_SUCCESS",
  TEMPLATE_CLONE_FAILURE: "ENTERPRISE_TEMPLATE_CLONE_FAILURE",

  TEMPLATE_CHANGE_CATEGORY_REQUEST:
    "ENTERPRISE_TEMPLATE_CHANGE_CATEGORY_REQUEST",
  TEMPLATE_CHANGE_CATEGORY_SUCCESS:
    "ENTERPRISE_TEMPLATE_CHANGE_CATEGORY_SUCCESS",
  TEMPLATE_CHANGE_CATEGORY_FAILURE:
    "ENTERPRISE_TEMPLATE_CHANGE_CATEGORY_FAILURE",

  TEMPLATE_DELETE_REQUEST: "ENTERPRISE_TEMPLATE_DELETE_REQUEST",
  TEMPLATE_DELETE_SUCCESS: "ENTERPRISE_TEMPLATE_DELETE_SUCCESS",
  TEMPLATE_DELETE_FAILURE: "ENTERPRISE_TEMPLATE_DELETE_FAILURE",

  TEMPLATE_CHANGE_GLOBAL_REQUEST: "ENTERPRISE_TEMPLATE_CHANGE_GLOBAL_REQUEST",
  TEMPLATE_CHANGE_GLOBAL_SUCCESS: "ENTERPRISE_TEMPLATE_CHANGE_GLOBAL_SUCCESS",
  TEMPLATE_CHANGE_GLOBAL_FAILURE: "ENTERPRISE_TEMPLATE_CHANGE_GLOBAL_FAILURE",
};
