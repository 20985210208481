import React from "react";
import { UncontrolledAlert } from "reactstrap";

export default class DismissibleAlert extends React.Component {
  render() {
    return (
      <UncontrolledAlert className={this.props.className} color={this.props.theme} fade={false}>
        <span className="alert-inner--text">{this.props.message}</span>
      </UncontrolledAlert>
    );
  }
}
