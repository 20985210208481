import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { ButtonLoading } from "../../components/Loading";
import DismissibleAlert from "../../components/common/DismissibleAlert";
import { userActions } from "_actions";

class Login extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const { loggedIn } = this.props;
    if (loggedIn) {
      this.props.history.push("/dashboard");
    }
  }

  constructor(props) {
    super(props);

    const { from } = this.props.location.state || {
      from: { pathname: "/dashboard" },
    };

    this.state = {
      username: "",
      password: "",
      from: from,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, from } = this.state;
    if (username && password) {
      this.props.login(username, password, from);
    }
  }

  render() {
    const { loggingIn, alert } = this.props;
    const { username, password, submitted } = this.state;
    return (
      <main ref="main">
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-secondary">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="main-content-container px-4 pt-4 mt-5">
            <main>
              <section className="section section-lg">
                <Container className="py-lg-4">
                  <Row className="justify-content-center">
                    <Col lg="5">
                      {alert.message && (
                        <DismissibleAlert
                          message={alert.message}
                          theme={alert.type}
                        />
                      )}
                      <Card className="shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                          <h3 className="text-center">Sign In</h3>
                          <Form role="form" onSubmit={this.handleSubmit}>
                            <FormGroup
                              className={`mb-3 ${
                                submitted && !username ? "has-danger" : ""
                              }`}
                            >
                              <InputGroup
                                className="input-group-alternative"
                                seamless
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText className="inputbox">
                                    <i className="ni ni-email-83" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  bsSize="lg"
                                  className={
                                    submitted && !username ? "is-invalid" : ""
                                  }
                                  placeholder="Username"
                                  name="username"
                                  type="text"
                                  value={username}
                                  onChange={this.handleChange}
                                />
                              </InputGroup>
                              {submitted && !username && (
                                <div className="invalid-feedback d-block">
                                  Username is required
                                </div>
                              )}
                            </FormGroup>
                            <FormGroup
                              className={
                                submitted && !username ? "has-danger" : ""
                              }
                            >
                              <InputGroup className="input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText className="inputbox">
                                    <i className="ni ni-lock-circle-open" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  bsSize="lg"
                                  className={
                                    submitted && !password ? "is-invalid" : ""
                                  }
                                  placeholder="Password"
                                  type="password"
                                  autoComplete="off"
                                  name="password"
                                  value={password}
                                  onChange={this.handleChange}
                                />
                              </InputGroup>
                              {submitted && !password && (
                                <div className="invalid-feedback d-block">
                                  Password is required
                                </div>
                              )}
                            </FormGroup>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id=" customCheckLogin"
                                type="checkbox"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor=" customCheckLogin"
                              >
                                <span>Remember me</span>
                              </label>
                            </div>
                            <div className="text-center">
                              <Button
                                className="mt-4"
                                color="primary"
                                type="submit"
                                size="lg"
                                disabled={loggingIn}
                              >
                                {loggingIn ? (
                                  <ButtonLoading showText={true} />
                                ) : (
                                  `Sign in`
                                )}
                              </Button>
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </section>
            </main>
          </Container>
        </section>
      </main>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  const { loggingIn, loggedIn } = state.authentication;
  const profile_loading = state.profile.loading;
  return { loggingIn, loggedIn, alert, profile_loading };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Login);
